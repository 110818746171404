import { AumeeComponent, Spacer } from '@dyrloz/aumee-design';
import './TotalData.css';

interface ITotalDataProps {
    label: string;
    value: number;
}

export class TotalData extends AumeeComponent<ITotalDataProps, {}> {
    constructor(props: ITotalDataProps) {
        super('TotalData', props);
    }

    render() {
        const { label, value } = this.props;

        const style = {
            '--total-value': value,
        } as React.CSSProperties;

        return (
            <div
                id={this.id}
                className="total-data aumee--flex-center aumee--flex-column"
            >
                <span className="label">{label}</span>
                <div className="value" style={style}></div>
            </div>
        );
    }
}
