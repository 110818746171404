import * as React from 'react';
import './EditableText.css';
import {
    AumeeComponent,
    Button,
    DeleteButton,
    Loader,
    SpanHTMLParser,
    TextArea,
} from '@dyrloz/aumee-design';
import { AumeeTranslator } from '@dyrloz/aumee-client';

interface IEditableTextProps {
    /**
     * Define if the text area is visible or not.
     */
    isInputVisible: boolean;

    /**
     * Define if the loader is visbile or not.
     */
    isLoadingVisible: boolean;

    /**
     * Define if the isAlready exists is visbile or not.
     */
    isKeyAlreadyExists?: boolean;

    /**
     * Define if the key is also editable or not.
     */
    isKeyEditable?: boolean;

    /**
     * This is the key that is actually edited.
     */
    keyDisplayed: string;

    /**
     * This is the value that is actually edited.
     */
    value: string;

    /**
     * Classname given to the editable text.
     */
    className?: string;

    /**
     * Placeholder of the text area.
     */
    placeholder: string;

    /**
     * To display text as HTML or plain text.
     */
    isHTML?: boolean;

    /**
     * Define if the user can edit this text.
     */
    isAllowedToEdit?: boolean;

    /**
     * Define if the user can delete this text.
     */
    isAllowedToDelete?: boolean;

    onEscapePress: (key: string) => void;
    onValidate: (key: string, value: string, newKey?: string) => void;
    onTextClick: (key: string) => void;
    onKeyChange?: (key: string) => void;
    onDelete?: (key: string) => void;
}

type EditableTextState = {};

/**
 * EditableText is a component that display a span text or html text>.
 * If clicked, then enter edit mode with a text area.
 * If loading, display a loader at the middle.
 */
export class EditableText extends AumeeComponent<
    IEditableTextProps,
    EditableTextState
> {
    private keyTextAreaRef: React.RefObject<TextArea>;
    private valueTextAreaRef: React.RefObject<TextArea>;

    constructor(props: IEditableTextProps) {
        super('EditableText', props);

        this.keyTextAreaRef = React.createRef();
        this.valueTextAreaRef = React.createRef();

        this.onValidate = this.onValidate.bind(this);
        this.onEdit = this.onEdit.bind(this);
    }

    private onValidate() {
        const value = this.valueTextAreaRef.current.state.value;
        if (value === '') return;

        let key = null;
        if (this.props.isKeyEditable) {
            key = this.keyTextAreaRef.current.state.value;
            if (key === '' || !key.match('^[a-z._0-9]*$')) return;
        }

        this.props.onValidate(
            this.props.keyDisplayed,
            this.props.isHTML ? value.replace(/\n/g, '<br>') : value,
            key,
        );
    }

    private onEdit(key: string) {
        this.props.onTextClick(key);

        setTimeout(() => {
            if (this.props.isKeyEditable) {
                this.keyTextAreaRef.current.focus();
            } else {
                this.valueTextAreaRef.current.focus();
            }
        }, 50);
    }

    render() {
        const {
            isInputVisible,
            isLoadingVisible,
            isKeyAlreadyExists = false,
            isKeyEditable = false,
            keyDisplayed,
            value = '',
            className = '',
            placeholder,
            isHTML = true,
            isAllowedToEdit = true,
            isAllowedToDelete = false,
            onEscapePress,
            onKeyChange = () => null,
        } = this.props;

        return (
            <div
                id={this.id}
                className={`editable-text ${className} ${
                    isAllowedToEdit ? 'edit' : ''
                } ${isInputVisible ? 'input' : 'display'}`}
                onClick={() => {
                    if (
                        !isInputVisible &&
                        !isLoadingVisible &&
                        isAllowedToEdit &&
                        !isAllowedToDelete
                    )
                        this.onEdit(keyDisplayed);
                }}
            >
                {isLoadingVisible && (
                    <div className="loader">
                        <Loader fatherId={this.id} size="tiny" />
                    </div>
                )}
                {!isInputVisible && (
                    <div>
                        {(!isHTML && value) || ''}
                        {isHTML && (
                            <SpanHTMLParser
                                fatherId={this.id}
                                className=""
                                label={
                                    (isKeyEditable
                                        ? keyDisplayed
                                              .replace('default.', '')
                                              .replace(/[.]/g, '<br>.')
                                              .replace(/[_]/g, '<br>_')
                                        : value) || ''
                                }
                            />
                        )}
                        {isAllowedToDelete && (
                            <div className="icon top-left">
                                <DeleteButton
                                    fatherId={this.id}
                                    usage="edit"
                                    iconSize="16"
                                    style="underlined"
                                    onClick={() =>
                                        this.props.onDelete(keyDisplayed)
                                    }
                                />
                            </div>
                        )}
                        {isAllowedToEdit && (
                            <div className="icon top-right">
                                <Button
                                    fatherId={this.id}
                                    usage="edit"
                                    icon="edit"
                                    labelSize="tiny"
                                    iconSize="16"
                                    style="underlined"
                                    onClick={() => this.onEdit(keyDisplayed)}
                                />
                            </div>
                        )}
                    </div>
                )}
                {isInputVisible && (
                    <div>
                        {isKeyEditable && (
                            <TextArea
                                ref={this.keyTextAreaRef}
                                fatherId={this.id}
                                usage={'key'}
                                size="fill"
                                pattern={
                                    isKeyAlreadyExists ? '-/-' : '^[a-z._0-9]*$'
                                }
                                errorMessage={
                                    isKeyAlreadyExists
                                        ? AumeeTranslator.translate(
                                              'translator.error_key_already_exists',
                                          )
                                        : AumeeTranslator.translate(
                                              'common.wrong_key_format',
                                          )
                                }
                                minLength={1}
                                errorMinLength={AumeeTranslator.translate(
                                    'translator.required',
                                )}
                                maxHeight={100}
                                value={keyDisplayed.replace('default.', '')}
                                placeholder={AumeeTranslator.translate(
                                    'translator.key',
                                )}
                                onEnterPress={() => this.onValidate()}
                                onEscapePress={() =>
                                    onEscapePress(keyDisplayed)
                                }
                                onValueChange={() => onKeyChange(keyDisplayed)}
                            />
                        )}
                        <TextArea
                            ref={this.valueTextAreaRef}
                            fatherId={this.id}
                            usage={'value'}
                            size="fill"
                            minLength={1}
                            errorMinLength={AumeeTranslator.translate(
                                'translator.required',
                            )}
                            maxHeight={350}
                            placeholder={placeholder}
                            value={
                                isHTML ? value.replace(/<br>/g, '\n') : value
                            }
                            type={!isHTML || isKeyEditable ? 'text' : 'html'}
                            onEnterPress={() => this.onValidate()}
                            onEscapePress={() => onEscapePress(keyDisplayed)}
                        />
                        <div className="icon top-left">
                            <Button
                                fatherId={this.id}
                                usage="cancel"
                                icon="return"
                                labelSize="tiny"
                                iconSize="16"
                                style="underlined"
                                onClick={() => onEscapePress(keyDisplayed)}
                            />
                        </div>
                        <div className="icon top-right">
                            <Button
                                fatherId={this.id}
                                usage="validate"
                                icon="validate"
                                labelSize="tiny"
                                iconSize="16"
                                style="underlined"
                                onClick={this.onValidate}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
