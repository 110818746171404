import { Direction, Side } from '@dyrloz/aumee-design';

export default [
    {
        id: 'accesses_total_users',
        position: 'vertical' as Direction,
        text: 'accesses.assistant.total',
        maxWidth: 200,
    },
    {
        id: 'accesses_button-group_type',
        position: 'vertical' as Direction,
        text: 'accesses.assistant.type',
        maxWidth: 200,
    },
    {
        id: 'accesses_input-text_account-name',
        position: 'vertical' as Direction,
        text: 'accesses.assistant.search',
        maxWidth: 200,
    },
    {
        id: 'accesses_button_refresh',
        position: 'vertical' as Direction,
        text: 'accesses.assistant.refresh',
        maxWidth: 200,
    },
    {
        id: 'accesses_container_accounts',
        position: 'vertical' as Direction,
        text: 'accesses.assistant.accounts',
        maxWidth: 340,
        forcedPosition: 'top' as Side,
    },
];
