import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

// Define a type for the slice state
interface AppRefreshDataState {
    value: string;
}

// Define the initial state using that type
const initialState: AppRefreshDataState = {
    value: '',
};

type AppRefreshDataPayload = {
    appRefreshData: string;
    appId?: string;
};

export const appRefreshDataSlice = createSlice({
    name: 'appRefreshData',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setAppRefreshData: (
            state,
            action: PayloadAction<AppRefreshDataPayload>,
        ) => {
            if (action.payload !== undefined)
                state.value = action.payload.appRefreshData;
        },
    },
});

export const { setAppRefreshData } = appRefreshDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAppRefreshData = (state: RootState) =>
    state.appRefreshData.value;

export default appRefreshDataSlice.reducer;
