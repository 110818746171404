import './FollowYou.css';
import { AumeeApp } from '../AumeeApp';
import { connect } from 'react-redux';
import { setAppLoading } from '../../store/slices/app-loading';
import { setAppRefreshData } from '../../store/slices/app-refresh-data';

type FollowYouState = {};

class FollowYou extends AumeeApp<FollowYouState> {
    constructor(props: any) {
        super('FollowYou', props);

        this.state = {};

        this.onEmailChange = this.onEmailChange.bind(this);

        this.props.dispatch(
            setAppLoading({
                appLoading: this.appName,
            }),
        );
        this.props.dispatch(
            setAppRefreshData({
                appRefreshData: this.appName,
                appId: this.props.appId,
            }),
        );
    }

    protected refreshAppData(): void {
        setTimeout(() => {
            this.props.dispatch(setAppLoading({ appLoading: '' }));
        }, 1500);
    }

    private onEmailChange(email: string) {
        this.setState({ email, pattern: '' });
    }

    render() {
        return (
            <div id={this.id} className="follow-you">
                SRE
            </div>
        );
    }
}

export default connect()(FollowYou);
