import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';

import appIdreducer, { setAppId } from './slices/app-id';
import userPermissionsOnAppreducer, {
    setUserPermissionsOnApp,
} from './slices/app-permissions';
import appLoadingReducer, { setAppLoading } from './slices/app-loading';
import appRefreshDataReducer from './slices/app-refresh-data';
import { AumeeAccounts } from '@dyrloz/aumee-client';
import checkAppToRefreshReducer, {
    checkAppToRefresh,
} from './slices/check-app-to-refresh';

// Create the middleware instance and methods
export const listenerMiddleware = createListenerMiddleware();

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
listenerMiddleware.startListening({
    actionCreator: setAppId,
    effect: async (action, listenerApi) => {
        const appId = action.payload.appId;
        console.debug('App id set : ', appId);
        listenerApi.dispatch(setAppLoading({ appLoading: null }));

        if (appId) {
            const permissions = await AumeeAccounts.getUserPermissions(appId);

            if (permissions) {
                listenerApi.dispatch(setUserPermissionsOnApp(permissions));
            }
        }

        if (!action.payload.avoidRefreshAppData) {
            listenerApi.dispatch(
                checkAppToRefresh({
                    appId: appId,
                }),
            );
        }
    },
});

export const store = configureStore({
    reducer: {
        appId: appIdreducer,
        userPermissionsOnApp: userPermissionsOnAppreducer,
        isAppLoading: appLoadingReducer,
        appRefreshData: appRefreshDataReducer,
        checkAppToRefresh: checkAppToRefreshReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
