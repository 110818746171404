import { useNavigate } from 'react-router-dom';
import { AumeeErrorBoundary } from '@dyrloz/aumee-design';
import { Toast } from '@dyrloz/aumee-design';
import {
    AumeeClient,
    AumeeLogger,
    AumeeTranslator,
    AumeeURL,
} from '@dyrloz/aumee-client';
import { useState } from 'react';
import Manager from './apps/Manager';

export const App = () => {
    AumeeURL.setNavigation(useNavigate());
    const [hasMessage, sethasMessage] = useState(false);
    const [message, setMessage] = useState('');

    AumeeClient.setOnServerError((error: Error) => {
        sethasMessage(true);
        setMessage(error.message);
    });

    return (
        <AumeeErrorBoundary
            onErrorCaught={(error: Error) => {
                AumeeLogger.logError(error);
                sethasMessage(true);
                setMessage('global');
            }}
        >
            <div>
                <Manager />
                <Toast
                    fatherId="app"
                    hasMessage={hasMessage}
                    message={AumeeTranslator.translate('error.' + message)}
                    color={
                        message.indexOf('warning') > -1 ? 'warning' : 'alert'
                    }
                    onExit={() => sethasMessage(false)}
                />
            </div>
        </AumeeErrorBoundary>
    );
};
