import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

// Define a type for the slice state
interface AppIdState {
    value: string;
}

// Define the initial state using that type
const initialState: AppIdState = {
    value: null,
};

type AppIdPayload = {
    appId: string;
    avoidRefreshAppData?: boolean;
};

export const appIdSlice = createSlice({
    name: 'appId',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setAppId: (state, action: PayloadAction<AppIdPayload>) => {
            state.value = action.payload.appId;
        },
    },
});

export const { setAppId } = appIdSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAppId = (state: RootState) => state.appId.value;

export default appIdSlice.reducer;
