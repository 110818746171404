import './Analyst.css';
import { AumeeApp } from '../AumeeApp';
import { connect } from 'react-redux';
import { setAppLoading } from '../../store/slices/app-loading';
import { setAppRefreshData } from '../../store/slices/app-refresh-data';
import { AumeeClient, AumeeTranslator, AumeeURL } from '@dyrloz/aumee-client';
import {
    Button,
    Container,
    DataList,
    DateTimePicker,
    PieChart,
    Spacer,
} from '@dyrloz/aumee-design';
import { TotalData } from '../../components/total-data/TotalData';
import { Filter } from '../../components/filter/Filter';

type AnalystState = {
    filters: { [key: string]: string };
    isDataLoading: boolean;
    countBySession: CountGroupBy;
    countByType: CountGroupBy;
    countByEvents: CountGroupBy;
    countByStatus: CountGroupBy;
    statusGroupByX: { [key: string]: number };
    statusGroupByKeys: string[];
    isRefreshNeeded: boolean;
};

type CountGroupBy = {
    total: number;
    data: { _id: string; sum: number }[];
};

/**
 * The Analyst app allows the user to check the data of the logs, with a total of logs, total of session,
 * One pie chart for repartition of logs' type, another pie for repartition of logs' status code,
 * And two data list for events and session details.
 */
class Analyst extends AumeeApp<AnalystState> {
    constructor(props: any) {
        super('Analyst', props);

        // GET VALUE FROM URL OR DEFAULT ONE, THEN SET IN URL
        const queryParameters = AumeeURL.extractURLParameters();

        const actualDate = new Date();

        const filters: { [key: string]: string } = {
            type: (queryParameters.type as string) || 'all',
            session_id: (queryParameters.session_id as string) || 'all',
            start_date:
                (queryParameters.start_date as string) ||
                new Date(
                    actualDate.getFullYear(),
                    actualDate.getMonth(),
                    actualDate.getDate(),
                ).toISOString(),
            end_date:
                (queryParameters.end_date as string) ||
                actualDate.toISOString(),
            event: decodeURIComponent(
                (queryParameters.event as string) || 'all',
            ),
            'data.status_code':
                (queryParameters['data.status_code'] as string) || 'all',
        };

        this.state = {
            filters,
            isDataLoading: false,
            countBySession: null,
            countByType: null,
            countByEvents: null,
            countByStatus: null,
            statusGroupByX: {},
            statusGroupByKeys: [],
            isRefreshNeeded: false,
        };

        for (const key in filters) {
            if (filters[key] !== 'all') {
                AumeeURL.addQueryParameterToURL(key + '=' + filters[key]);
            }
        }

        this.onFilterChange = this.onFilterChange.bind(this);
        this.onReset = this.onReset.bind(this);
        this.onTypeLegendClicked = this.onTypeLegendClicked.bind(this);
        this.onStatusLegendClicked = this.onStatusLegendClicked.bind(this);
        this.onSessionSelected = this.onSessionSelected.bind(this);
        this.onEventSelected = this.onEventSelected.bind(this);

        this.props.dispatch(
            setAppLoading({
                appLoading: this.appName,
            }),
        );
        this.props.dispatch(
            setAppRefreshData({
                appRefreshData: this.appName,
                appId: this.props.appId,
            }),
        );
    }

    protected async refreshAppData(): Promise<void> {
        this.setState({
            isDataLoading: true,
        });
        const requestParameters = AumeeURL.getURLParametersQuery();
        if (
            requestParameters.indexOf('start_date') === -1 ||
            requestParameters.indexOf('end_date') === -1
        )
            return;

        const groups = ['session_id', 'type', 'data.status_code', 'event'];
        const results: CountGroupBy[] = [];

        for (const group of groups) {
            const count: CountGroupBy = await AumeeClient.get(
                '/logs/count' + requestParameters + '&group_by=' + group,
            )
                .then((res) => res.json())
                .then((res) => res.data);

            results.push({
                ...count,
                data: count.data
                    .filter((d) => d._id)
                    .map((d) => {
                        return { ...d, _id: d._id.toString() };
                    }),
            });
        }

        const statusGroupByX = results[2].data.reduce(
            (status: { [key: string]: number }, data) => {
                if (status[data._id[0]] === undefined) {
                    status[data._id[0]] = 0;
                }
                status[data._id[0]] += data.sum;

                return status;
            },
            {},
        );

        const statusGroupByKeys = Object.keys(statusGroupByX || {});
        this.setState({
            countBySession: results[0],
            countByType: results[1],
            countByStatus: results[2],
            countByEvents: results[3],
            statusGroupByX,
            statusGroupByKeys,
            isRefreshNeeded: false,
        });

        this.props.dispatch(setAppLoading({ appLoading: '' }));
        this.setState({
            isDataLoading: false,
        });
    }

    private onFilterChange(attribute: string, value: string) {
        if (value !== 'all') {
            AumeeURL.addQueryParameterToURL(attribute + '=' + value);
        } else {
            AumeeURL.removeQueryParameterFromURL(attribute);
        }
        this.setState({
            filters: {
                ...this.state.filters,
                [attribute]: value,
            },
            isRefreshNeeded: true,
        });
    }

    private onReset() {
        const actualDate = new Date();

        const filters: { [key: string]: string } = {
            ...this.state.filters,
            event: 'all',
            session_id: 'all',
            type: 'all',
            start_date: new Date(
                actualDate.getFullYear(),
                actualDate.getMonth(),
                actualDate.getDate(),
            ).toISOString(),
            end_date: actualDate.toISOString(),
            'data.status_code': 'all',
        };

        this.setState({
            filters,
            isRefreshNeeded: true,
        });

        for (const key in filters) {
            if (filters[key] !== 'all') {
                AumeeURL.addQueryParameterToURL(key + '=' + filters[key]);
            } else {
                AumeeURL.removeQueryParameterFromURL(key);
            }
        }
    }

    private onTypeLegendClicked(index: number) {
        let actualTypesSelected = this.state.filters.type
            .replace('all', '')
            .split(',');
        if (actualTypesSelected.length === 1 && actualTypesSelected[0] === '') {
            actualTypesSelected = [];
        }

        const newTypeSelected = this.state.countByType.data[index]._id;
        let newTypes: string[] = [];
        if (actualTypesSelected.includes(newTypeSelected)) {
            newTypes = actualTypesSelected.filter((t) => t !== newTypeSelected);
        } else {
            newTypes = actualTypesSelected.concat(newTypeSelected);
        }

        if (newTypes.length === 0) {
            newTypes.push('all');
        }

        this.onFilterChange('type', newTypes.join(','));
    }

    private onStatusLegendClicked(index: number) {
        const statusCodeClicked = this.state.statusGroupByKeys[index];
        const actualStatusCodeSelected = this.state.filters['data.status_code'];
        const statusCodeClickedValue = statusCodeClicked + 'xx';

        let newStatusCodeSelected: string;
        if (actualStatusCodeSelected === statusCodeClickedValue) {
            newStatusCodeSelected = 'all';
        } else {
            newStatusCodeSelected = statusCodeClickedValue;
        }

        this.onFilterChange('data.status_code', newStatusCodeSelected);
    }

    private onSessionSelected(newSessions: { id: string }[]) {
        this.onFilterChange('session_id', newSessions[0]?.id || 'all');
    }

    private onEventSelected(newEvents: { id: string }[]) {
        if (newEvents.length === 0) {
            this.onFilterChange('event', 'all');
        } else {
            this.onFilterChange('event', newEvents.map((e) => e.id).join(','));
        }
    }

    render() {
        const { isTabletScreen, isMobileScreen } = this.props;

        const {
            filters,
            isDataLoading,
            countBySession,
            countByType,
            countByEvents,
            countByStatus,
            statusGroupByX,
            statusGroupByKeys,
            isRefreshNeeded,
        } = this.state;

        return (
            <div id={this.id} className="analyst page">
                <Container fatherId={this.id} usage="inputs">
                    <div className="aumee--flex-center aumee--flex-column">
                        <div className="inputs aumee--flex-center">
                            <DateTimePicker
                                fatherId={this.id}
                                usage="start-date"
                                type="datetime-local"
                                step="milliseconds"
                                value={filters['start_date'] as string}
                                placeholder={AumeeTranslator.translate(
                                    'logs.start_date',
                                )}
                                onValueChange={(date: string) => {
                                    this.onFilterChange('start_date', date);
                                }}
                            />
                            <DateTimePicker
                                fatherId={this.id}
                                usage="end-date"
                                type="datetime-local"
                                step="milliseconds"
                                value={filters['end_date'] as string}
                                min={filters['start_date'] as string}
                                placeholder={AumeeTranslator.translate(
                                    'logs.end_date',
                                )}
                                onValueChange={(date: string) => {
                                    this.onFilterChange('end_date', date);
                                }}
                            />
                            <div className="aumee--flex-center">
                                <Button
                                    fatherId={this.id}
                                    usage="refresh"
                                    icon="refresh"
                                    iconSize="24"
                                    onClick={this.refreshAppData}
                                    disabled={!isRefreshNeeded}
                                />
                                <Spacer size="tiny" direction="vertical" />
                                <Button
                                    fatherId={this.id}
                                    usage="reset"
                                    icon="cross"
                                    iconSize="24"
                                    style="warning"
                                    onClick={this.onReset}
                                />
                            </div>
                        </div>
                        <div className="filters aumee--flex-center">
                            {['type', 'data.status_code', 'session_id', 'event']
                                .filter((f) => filters[f] !== 'all')
                                .map((f) => (
                                    <Filter
                                        fatherId={this.id}
                                        usage={f.replace(/[._]/g, '-')}
                                        label={AumeeTranslator.translate(
                                            'analyst.filter.' + f,
                                        )}
                                        value={filters[f]}
                                        onReset={() =>
                                            this.onFilterChange(f, 'all')
                                        }
                                    />
                                ))}

                            {[
                                'type',
                                'data.status_code',
                                'session_id',
                                'event',
                            ].filter((f) => filters[f] !== 'all').length ===
                                0 && (
                                <span className="no-filter">
                                    {AumeeTranslator.translate(
                                        'analyst.fitler.no_filter',
                                    )}
                                </span>
                            )}
                        </div>
                    </div>
                </Container>
                <Spacer />
                <Container
                    fatherId={this.id}
                    usage="data"
                    padding="epic"
                    isLoading={isDataLoading}
                >
                    {countBySession &&
                        countByType &&
                        countByStatus &&
                        countByEvents && (
                            <div className="row aumee--flex-center">
                                <div className="column aumee--flex-center aumee--flex-column">
                                    <div
                                        className={
                                            'totals aumee--flex-center ' +
                                            (isMobileScreen
                                                ? 'aumee--flex-column'
                                                : '')
                                        }
                                    >
                                        <TotalData
                                            fatherId={this.id}
                                            usage="logs"
                                            label={AumeeTranslator.translate(
                                                'analyst.total.logs',
                                            )}
                                            value={countByType.total || 0}
                                        />
                                        <TotalData
                                            fatherId={this.id}
                                            usage="session"
                                            label={AumeeTranslator.translate(
                                                'analyst.total.session',
                                            )}
                                            value={
                                                countBySession.data?.length || 0
                                            }
                                        />
                                    </div>
                                    <div className="graphs aumee--flex-center">
                                        <PieChart
                                            fatherId={this.id}
                                            usage="type"
                                            label={AumeeTranslator.translate(
                                                'analyst.pie.type.label',
                                            )}
                                            values={countByType.data.map(
                                                (d) => d.sum,
                                            )}
                                            legendsText={countByType.data.map(
                                                (d) =>
                                                    AumeeTranslator.translate(
                                                        'analyst.pie.type.' +
                                                            d._id,
                                                    ),
                                            )}
                                            valuesColor={countByType.data.map(
                                                (d) => `--logs-${d._id}-color`,
                                            )}
                                            isColorsNotFromTheme={true}
                                            legendsSide={
                                                isMobileScreen
                                                    ? 'bottom'
                                                    : 'left'
                                            }
                                            removeLegendsWithNoValue={true}
                                            onLegendClick={
                                                this.onTypeLegendClicked
                                            }
                                        />
                                        <Spacer
                                            size="small"
                                            direction={
                                                isMobileScreen
                                                    ? 'horizontal'
                                                    : 'vertical'
                                            }
                                        />
                                        {statusGroupByKeys.length > 1 && (
                                            <PieChart
                                                fatherId={this.id}
                                                usage="status-code"
                                                label={AumeeTranslator.translate(
                                                    'analyst.pie.status.label',
                                                )}
                                                values={statusGroupByKeys.map(
                                                    (s) => statusGroupByX[s],
                                                )}
                                                legendsText={statusGroupByKeys.map(
                                                    (s) =>
                                                        AumeeTranslator.translate(
                                                            'analyst.pie.status.' +
                                                                s +
                                                                'xx',
                                                        ),
                                                )}
                                                valuesColor={statusGroupByKeys.map(
                                                    (s) =>
                                                        `--status-${s}xx-color`,
                                                )}
                                                isColorsNotFromTheme={true}
                                                legendsSide={
                                                    isMobileScreen
                                                        ? 'bottom'
                                                        : 'right'
                                                }
                                                removeLegendsWithNoValue={true}
                                                onLegendClick={
                                                    this.onStatusLegendClicked
                                                }
                                            />
                                        )}
                                        {statusGroupByKeys.length === 1 && (
                                            <PieChart
                                                fatherId={this.id}
                                                usage="status-code-details"
                                                label={AumeeTranslator.translate(
                                                    'analyst.pie.status_details.label',
                                                )}
                                                values={countByStatus.data.map(
                                                    (s) => s.sum,
                                                )}
                                                legendsText={countByStatus.data.map(
                                                    (s) => s._id,
                                                )}
                                                valuesColor={[
                                                    `--status-${statusGroupByKeys[0]}xx-color`,
                                                ]}
                                                isColorsNotFromTheme={true}
                                                legendsSide={
                                                    isMobileScreen
                                                        ? 'bottom'
                                                        : 'right'
                                                }
                                                removeLegendsWithNoValue={true}
                                            />
                                        )}
                                        {isMobileScreen &&
                                            statusGroupByKeys.length > 0 && (
                                                <Spacer
                                                    size="small"
                                                    direction={'horizontal'}
                                                />
                                            )}
                                    </div>
                                </div>
                                <div className="column lists aumee--flex-center">
                                    <DataList
                                        fatherId={this.id}
                                        usage="session-id"
                                        list={countBySession.data.map((d) => {
                                            return {
                                                id: d._id,
                                                display: d._id,
                                                data: d.sum,
                                            };
                                        })}
                                        valueList={
                                            filters.session_id !== 'all'
                                                ? [{ id: filters.session_id }]
                                                : []
                                        }
                                        onListChange={this.onSessionSelected}
                                        isSoloValue={true}
                                        iconNotSelected="file"
                                        notFoundMessage={AumeeTranslator.translate(
                                            'common.not_found',
                                        )}
                                        searchPlaceholder={AumeeTranslator.translate(
                                            'common.search',
                                        )}
                                    />
                                    <Spacer
                                        size="small"
                                        direction={
                                            isTabletScreen && !isMobileScreen
                                                ? 'vertical'
                                                : 'horizontal'
                                        }
                                    />
                                    <DataList
                                        fatherId={this.id}
                                        usage="events"
                                        list={countByEvents.data.map((d) => {
                                            return {
                                                id: d._id,
                                                display: d._id,
                                                data: d.sum,
                                            };
                                        })}
                                        valueList={
                                            filters.event !== 'all'
                                                ? filters.event
                                                      .split(',')
                                                      .map((e) => {
                                                          return { id: e };
                                                      })
                                                : []
                                        }
                                        onListChange={this.onEventSelected}
                                        notFoundMessage={AumeeTranslator.translate(
                                            'common.not_found',
                                        )}
                                        searchPlaceholder={AumeeTranslator.translate(
                                            'common.search',
                                        )}
                                        totalLabel={AumeeTranslator.translate(
                                            'common.total',
                                        )}
                                    />
                                </div>
                            </div>
                        )}
                </Container>
            </div>
        );
    }
}

export default connect()(Analyst);
