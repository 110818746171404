import React from 'react';
import { AppDispatch, listenerMiddleware } from '../store/store';
import { UnsubscribeListener } from '@reduxjs/toolkit';
import { setAppRefreshData } from '../store/slices/app-refresh-data';
import { AumeeTranslator } from '@dyrloz/aumee-client';
import { Assistant, splitName, AssistantStep } from '@dyrloz/aumee-design';

interface IAumeeAppProps {
    appId: string;
    userPermissionsOnApp: string[];
    dispatch: AppDispatch;
    menuPosition: 'left' | 'top';
    isMobileScreen: boolean;
    isTabletScreen: boolean;
}

export abstract class AumeeApp<S> extends React.Component<IAumeeAppProps, S> {
    protected appName: string;
    protected id: string;
    protected assistantSteps;
    private static lastAppNameLoading: string = '';
    private unsubscribeListener: UnsubscribeListener;

    constructor(
        appName: string,
        props: IAumeeAppProps,
        assistantJsonSteps?: AssistantStep[],
    ) {
        super(props);

        this.appName = appName;

        this.id = splitName(appName);

        if (assistantJsonSteps) {
            this.assistantSteps = this.formatAssistantSteps(assistantJsonSteps);
        }

        this.refreshAppData = this.refreshAppData.bind(this);

        this.unsubscribeListener = listenerMiddleware.startListening({
            actionCreator: setAppRefreshData,
            effect: async (action) => {
                if (action.payload.appRefreshData !== null) {
                    AumeeApp.lastAppNameLoading = action.payload.appRefreshData;
                } else {
                    AumeeApp.lastAppNameLoading = this.appName;
                }

                if (
                    AumeeApp.lastAppNameLoading === this.appName &&
                    action.payload.appId === this.props.appId
                ) {
                    console.debug(
                        this.appName +
                            ' is loading... for ' +
                            action.payload.appId +
                            ' with id ' +
                            this.props.appId,
                    );
                    this.refreshAppData(action.payload.appId);
                }
            },
        });
    }

    componentWillUnmount() {
        console.debug('Unmount page : ' + this.appName);
        this.unsubscribeListener();
    }

    private formatAssistantSteps(
        assistantJsonSteps: AssistantStep[],
    ): AssistantStep[] {
        return assistantJsonSteps.map((step) => {
            return {
                id: step.id,
                position: step.position,
                text: AumeeTranslator.translate(step.text),
                maxWidth: step.maxWidth,
                forcedPosition: step.forcedPosition,
            };
        });
    }

    protected getAssistant(filter = (step: any) => true) {
        return (
            <Assistant
                fatherId={this.id}
                steps={this.assistantSteps.filter(filter)}
                offsetLeft={this.props.menuPosition === 'left' ? 170 : 0}
                offsetTop={this.props.menuPosition === 'top' ? 70 : 0}
                lastStepButtonLabel={AumeeTranslator.translate('common.close')}
            />
        );
    }

    protected abstract refreshAppData(appId?: string): void;
}
