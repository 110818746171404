import { Direction } from '@dyrloz/aumee-design';

export default [
    {
        id: 'marketor_select_template',
        position: 'vertical' as Direction,
        text: 'marketor.template.select',
        maxWidth: 200,
    },
    {
        id: 'marketor_button_new-template',
        position: 'vertical' as Direction,
        text: 'marketor.template.add',
        maxWidth: 200,
    },
    {
        id: 'marketor_editable-element_template-name',
        position: 'vertical' as Direction,
        text: 'marketor.template.name',
        maxWidth: 250,
    },
    {
        id: 'marketor_select-locale',
        position: 'vertical' as Direction,
        text: 'marketor.template.locale',
        maxWidth: 200,
    },
    {
        id: 'marketor_switch_use-app-color',
        position: 'vertical' as Direction,
        text: 'marketor.template.use_app_color',
        maxWidth: 200,
    },
    {
        id: 'marketor_switch_use-header-footer',
        position: 'vertical' as Direction,
        text: 'marketor.template.use_header_footer',
        maxWidth: 200,
    },
    {
        id: 'marketor_container_locale-mail',
        position: 'vertical' as Direction,
        text: 'marketor.template.locale_mail',
        maxWidth: 300,
    },
];
