import './Marketor.css';
import { AumeeApp } from '../AumeeApp';
import { connect } from 'react-redux';
import { setAppLoading } from '../../store/slices/app-loading';
import { setAppRefreshData } from '../../store/slices/app-refresh-data';
import assistantSteps from './marketor.assistant';
import {
    ActivableList,
    Button,
    ButtonGroup,
    Container,
    DataList,
    Direction,
    IconFactory,
    Locales,
    Select,
    Spacer,
    SpanHTMLParser,
} from '@dyrloz/aumee-design';
import {
    AumeeAccounts,
    AumeeClient,
    AumeeTranslator,
    AumeeURL,
} from '@dyrloz/aumee-client';
import parse from 'html-react-parser';
import * as React from 'react';

type MarketorState = {
    appInfoVars: {
        [key: string]: string;
    };
    usersWithAccess: UserToSelect[];
    usersToSend: string[];
    templates: TemplateSummary[];
    templateSelected: Template;
    filterUsersBy: string;

    isContentLoading: boolean;
    isSendingMail: boolean;
};

type UserToSelect = {
    id: string;
    display: string;
    appAccess: number;
};

type TemplateSummary = {
    id: string;
    name: string;
};

type Template = TemplateSummary & {
    vars: string[];
    use_app_color: boolean;
    use_header_footer: boolean;
    locales_mail: {
        locale: Locales;
        title: string;
        content: string;
    }[];
};

type UserProfile = {
    email: string;
    locale: string;
};

class Marketor extends AumeeApp<MarketorState> {
    private sendAnimationRef: React.RefObject<HTMLDivElement>;
    constructor(props: any) {
        super('Marketor', props, assistantSteps);

        const quertyParameters = AumeeURL.extractURLParameters();

        this.state = {
            appInfoVars: {},
            usersWithAccess: [],
            usersToSend: [],
            templates: [],
            templateSelected: null,
            filterUsersBy: 'users',
            isContentLoading: false,
            isSendingMail: false,
        };

        this.sendAnimationRef = React.createRef();

        this.onEditTemplate = this.onEditTemplate.bind(this);
        this.onTemplateSelected = this.onTemplateSelected.bind(this);
        this.onSendMail = this.onSendMail.bind(this);
        this.onUsersToSendChange = this.onUsersToSendChange.bind(this);
        this.onFilterUsers = this.onFilterUsers.bind(this);
        this.filterUsers = this.filterUsers.bind(this);

        this.props.dispatch(
            setAppLoading({
                appLoading: this.appName,
            }),
        );
        this.props.dispatch(
            setAppRefreshData({
                appRefreshData: this.appName,
                appId: this.props.appId,
            }),
        );
    }

    protected async refreshAppData() {
        const appInfo = await AumeeAccounts.getAppInfo(this.props.appId, false);

        const appInfoVars: { [key: string]: string } = {};
        appInfoVars['default.app.account_name'] = appInfo.account_name;
        appInfoVars['default.app.icon'] = appInfo.icon;
        appInfoVars['default.app.url'] = appInfo.url;
        appInfoVars['default.app.colors.primary'] =
            appInfo.colors.light.primary.value;
        appInfoVars['default.app.colors.secondary'] =
            appInfo.colors.light.secondary.value;
        appInfoVars['default.app.colors.tertiary'] =
            appInfo.colors.light.tertiary.value;
        appInfoVars['default.app.colors.alert'] =
            appInfo.colors.light.alert.value;
        appInfoVars['default.app.colors.warning'] =
            appInfo.colors.light.warning.value;

        this.setState({
            appInfoVars,
        });

        await this.refreshAccountsAccesses();
        await this.refreshTemplates();
        this.props.dispatch(setAppLoading({ appLoading: '' }));
    }

    private async refreshAccountsAccesses() {
        const queryParameters = AumeeURL.extractURLParameters();

        // GET TOTAL OF ACCOUNTS WITH ONLY app_id and type = user
        const count = await AumeeClient.get(
            '/accounts/count?type=user&app_id=' + queryParameters.app_id,
        )
            .then((res) => res.json())
            .then((res) => res.data.total_valid_access);

        // GET ACCOUNTS WITH FULL PARAMETERS
        let usersWithAccess: UserToSelect[] = [];
        const maxRange = 100;

        for (let nbPage = 1; nbPage <= Math.ceil(count / maxRange); nbPage++) {
            usersWithAccess = usersWithAccess.concat(
                await AumeeClient.get(
                    `/accounts?type=user&app_id=${queryParameters.app_id}&range=${maxRange}&page=${nbPage}`,
                )
                    .then((res) => res.json())
                    .then((res) =>
                        res.data.map((u: any) => {
                            return {
                                id: u.id,
                                display: u.account_name.split('/|/')[0],
                                appAccess: u.app_access,
                            };
                        }),
                    ),
            );
        }

        this.setState({
            usersWithAccess,
            usersToSend: usersWithAccess.map((u) => u.id),
        });
    }

    protected async refreshTemplates() {
        const templates: TemplateSummary[] = await AumeeClient.get(
            '/templates?app_id=' + this.props.appId + '&use_for_marketor=true',
        )
            .then((res) => res.json())
            .then((res) => res.data);

        templates.sort((a, b) => (a.name > b.name ? 1 : -1));

        const templateId =
            templates.find(
                (t) => t.id === AumeeURL.extractURLParameters().template_id,
            )?.id || templates[0]?.id;

        if (templateId) {
            this.setState({
                templates,
                templateSelected: null,
            });
            const templateSelected = await this.selectTemplate(templateId);
        } else {
            this.setState({
                templates,
                templateSelected: null,
                isContentLoading: false,
            });
        }
    }

    private async selectTemplate(id: string) {
        // 404 ?
        AumeeURL.addQueryParameterToURL('template_id=' + id);

        const templateSelected: Template = await AumeeClient.get(
            '/templates/' + id,
        )
            .then((res) => res.json())
            .then((res) => res.data);

        this.setState({
            templateSelected,
            isContentLoading: false,
        });

        return templateSelected;
    }

    private replaceAppInfo(content: string) {
        if (
            this.state.templateSelected.name === 'header' ||
            this.state.templateSelected.name === 'footer'
        ) {
            const appInfoVars = this.state.templateSelected.vars.filter(
                (v) =>
                    v.indexOf('default.app.') === 0 &&
                    v.indexOf('default.app.colors') < 0,
            );

            for (const infoVar of appInfoVars) {
                content = content.replace(
                    new RegExp('\\${' + infoVar + '}', 'g'),
                    this.state.appInfoVars[infoVar],
                );
            }

            if (this.state.templateSelected.name === 'header') {
                content = content.replace(
                    new RegExp('\\${default.mail.title}', 'g'),
                    this.state.templateSelected.locales_mail.find(
                        (lm) => lm.locale === 'en',
                    ).title,
                );
            }
        }

        if (!this.state.templateSelected.use_app_color) return content;

        const appColorsVars = this.state.templateSelected.vars.filter(
            (v) => v.indexOf('default.app.colors.') === 0,
        );

        for (const colorVar of appColorsVars) {
            content = content.replace(
                new RegExp('\\${' + colorVar + '}', 'g'),
                this.state.appInfoVars[colorVar],
            );
        }

        return content;
    }

    private replaceNewLine(content: string) {
        return content
            .replace(/>\n/g, '>\r')
            .replace(/\n/g, '<br>')
            .replace(/>\r/g, '>\n');
    }

    private async onEditTemplate() {
        window.open(
            AumeeURL.getURL().replace('marketor', 'notificator'),
            '_blank',
        );
    }

    private async onTemplateSelected(id: string) {
        this.setState({
            isContentLoading: true,
        });

        await this.selectTemplate(id);
    }

    private onUsersToSendChange(users: { id: string }[]) {
        this.setState({
            usersToSend: users.map((u) => u.id),
        });
    }

    private async onSendMail() {
        this.sendAnimationRef.current.classList.add('running');
        setTimeout(() => {
            this.sendAnimationRef.current.classList.remove('running');
        }, 1700);

        this.setState({
            isSendingMail: true,
        });

        const mailData: { [key: string]: string } = {};
        this.state.templateSelected.vars
            .filter((v) => v.indexOf('default.') !== 0)
            .forEach((v) => {
                mailData[v] = v.toUpperCase();
            });

        await AumeeClient.post(
            '/mails/templates/' + this.state.templateSelected.id + '/mass',
            {
                destinatorsId: this.state.usersToSend,
            },
        );

        this.setState({
            isSendingMail: false,
        });
    }

    private onFilterUsers(value: string) {
        this.setState({
            filterUsersBy: value,
        });
    }

    private filterUsers(user: UserToSelect) {
        if (this.state.filterUsersBy === 'users') return true;

        const userAppAccess = user.appAccess;

        switch (this.state.filterUsersBy) {
            case 'validate':
                return userAppAccess === 1;
            case 'delete':
                return userAppAccess === -1;
            case 'help':
                return userAppAccess === 0;
            case 'email_send':
                return this.state.usersToSend.includes(user.id);
            default:
                return false;
        }
    }

    render() {
        const { isMobileScreen } = this.props;

        const {
            templates,
            templateSelected,
            usersWithAccess,
            usersToSend,
            filterUsersBy,
            isContentLoading,
            isSendingMail,
        } = this.state;

        const localeSelected = 'en';
        const orientation = isMobileScreen ? 'horizontal' : 'vertical';

        const hasTemplateWritePermission =
            this.props.userPermissionsOnApp.includes('template.write');

        const usersFiltered = usersWithAccess.filter(this.filterUsers);

        return (
            <div id={this.id} className="marketor page">
                {templateSelected && this.getAssistant()}
                {templates.length > 0 && (
                    <Container
                        fatherId={this.id}
                        usage="inputs"
                        padding="large"
                    >
                        <div className="aumee--flex-center inputs">
                            <div className="aumee--flex-center">
                                <Select
                                    fatherId={this.id}
                                    usage="template"
                                    size="long"
                                    withInput={true}
                                    isInfoTextDisplayed={true}
                                    placeholder={AumeeTranslator.translate(
                                        'notificator.template',
                                    )}
                                    values={templates.map((t) => {
                                        return { value: t.id, text: t.name };
                                    })}
                                    defaultValue={templateSelected?.id || ''}
                                    onValueChange={this.onTemplateSelected}
                                    optionsMaxSize="medium"
                                />
                                <Spacer direction="vertical" size="tiny" />
                                <Button
                                    fatherId={this.id}
                                    usage="edit-template"
                                    icon="edit"
                                    iconSize="24"
                                    onClick={this.onEditTemplate}
                                    disabled={!hasTemplateWritePermission}
                                />
                            </div>
                            <div className="send-mass-mails">
                                {usersToSend.length > 0 && (
                                    <div
                                        id={this.id + '_send-animation'}
                                        ref={this.sendAnimationRef}
                                    >
                                        {[2, 42, 121, 162, 227, 320].map(
                                            (rotation) => {
                                                return IconFactory.createIcon({
                                                    icon: 'email_open',
                                                    color: 'primary',
                                                    colorVariant: 'dark',
                                                    rotation,
                                                });
                                            },
                                        )}
                                    </div>
                                )}
                                <Button
                                    fatherId={this.id}
                                    usage="send-mass-mails"
                                    label={AumeeTranslator.translate(
                                        'marketor.send_mails',
                                    )}
                                    icon="email_send"
                                    onClick={this.onSendMail}
                                    disabled={
                                        usersToSend.length === 0 ||
                                        isSendingMail
                                    }
                                />
                            </div>
                        </div>
                    </Container>
                )}
                {templates.length > 0 && <Spacer />}
                <Container
                    fatherId={this.id}
                    usage="locale-mail"
                    isLoading={isContentLoading}
                    padding="epic"
                >
                    <div className="aumee--flex-center locale-mail">
                        {templates.length === 0 && (
                            <div className="not-found aumee--flex-center aumee--flex-column">
                                <SpanHTMLParser
                                    label={AumeeTranslator.translate(
                                        'marketor.template.not_found',
                                    )}
                                />
                                {hasTemplateWritePermission && <Spacer />}
                                {hasTemplateWritePermission && (
                                    <Button
                                        fatherId={this.id}
                                        usage="edit-template"
                                        icon="edit"
                                        iconSize="24"
                                        onClick={this.onEditTemplate}
                                        disabled={!hasTemplateWritePermission}
                                    />
                                )}
                            </div>
                        )}

                        {templates.length > 0 && templateSelected && (
                            <div className="mail">
                                <div className="render">
                                    <span className="title">
                                        {
                                            templateSelected.locales_mail.find(
                                                (lm) =>
                                                    lm.locale ===
                                                    localeSelected,
                                            ).title
                                        }
                                    </span>
                                    <Spacer />
                                    <div className="content">
                                        {parse(
                                            this.replaceNewLine(
                                                this.replaceAppInfo(
                                                    templateSelected.locales_mail.find(
                                                        (lm) =>
                                                            lm.locale ===
                                                            localeSelected,
                                                    ).content,
                                                ),
                                            ),
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {templates.length > 0 && templateSelected && (
                            <Spacer
                                direction={orientation}
                                size={
                                    orientation === 'horizontal'
                                        ? 'tiny'
                                        : 'medium'
                                }
                            />
                        )}
                        {templates.length > 0 && templateSelected && (
                            <div className="users aumee--flex-center aumee--flex-column">
                                <span>
                                    {AumeeTranslator.translate(
                                        'marketor.users',
                                    )}
                                </span>
                                <Spacer size="small" />
                                <ButtonGroup
                                    fatherId={this.id}
                                    usage="access"
                                    values={[
                                        'users',
                                        'validate',
                                        'delete',
                                        'help',
                                        'email_send',
                                    ]}
                                    value={filterUsersBy}
                                    valueType="icon"
                                    labelSize="small"
                                    onClick={this.onFilterUsers}
                                />
                                <Spacer size="small" />
                                <div className="total aumee--flex-center">
                                    <Button
                                        fatherId={this.id}
                                        usage="reset-users-send"
                                        icon="trash"
                                        onClick={() =>
                                            this.setState({
                                                usersToSend: [],
                                            })
                                        }
                                        disabled={usersToSend.length === 0}
                                    />
                                    <Spacer direction="vertical" size="small" />
                                    <span>
                                        {usersToSend.length} /{' '}
                                        {usersWithAccess.length}
                                    </span>
                                    <Spacer direction="vertical" size="small" />
                                    <Button
                                        fatherId={this.id}
                                        usage="refresh-users-send"
                                        icon="refresh"
                                        onClick={() =>
                                            this.setState({
                                                usersToSend:
                                                    usersWithAccess.map(
                                                        (u) => u.id,
                                                    ),
                                            })
                                        }
                                        disabled={
                                            usersToSend.length ===
                                            usersWithAccess.length
                                        }
                                    />
                                </div>
                                <Spacer size="small" />
                                {usersWithAccess.length > 0 && (
                                    <DataList
                                        fatherId={this.id}
                                        usage="vars"
                                        list={usersFiltered.map((u) => {
                                            return {
                                                ...u,
                                                data: 1,
                                            };
                                        })}
                                        searchPlaceholder={AumeeTranslator.translate(
                                            'common.search',
                                        )}
                                        valueList={usersToSend
                                            .filter((u) =>
                                                usersFiltered.find(
                                                    (ua) => ua.id === u,
                                                ),
                                            )
                                            .map((id) => {
                                                return { id };
                                            })}
                                        notFoundMessage={AumeeTranslator.translate(
                                            'marketor.no_user_found',
                                        )}
                                        onListChange={this.onUsersToSendChange}
                                        isDataDisplayed={false}
                                        isItemClickable={true}
                                        totalLabel={AumeeTranslator.translate(
                                            'common.total',
                                        )}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </Container>
            </div>
        );
    }
}

export default connect()(Marketor);
