import './AppInfo.css';
import { AumeeApp } from '../AumeeApp';
import { connect } from 'react-redux';
import { setAppLoading } from '../../store/slices/app-loading';
import { setAppRefreshData } from '../../store/slices/app-refresh-data';
import {
    AumeeAccounts,
    AumeeAnalytics,
    AumeeClient,
    AumeeTranslator,
    AumeeURL,
} from '@dyrloz/aumee-client';
import {
    Button,
    Color,
    ColorName,
    Container,
    Divider,
    EditableElement,
    EditableList,
    EditableObject,
    ShadeColorDisplay,
    ShadeColorPicker,
    Spacer,
    Switch,
    ThemeName,
    ThemesColors,
    Tooltip,
} from '@dyrloz/aumee-design';
import { EditAppInfo } from '../../components/edit-app-info/EditAppInfo';
import assistantSteps from './app-info.assistant';

type AppInfoState = {
    basic: string;
    hasBasicBeenCopied: boolean;
    hasConfirmedResetBasic: boolean;
    isEditModeForNode: {
        info: boolean;
        colors: boolean;
        permissions: {
            [key: string]: boolean;
        };
        roles: {
            [key: string]: boolean;
        };
    };
    isNodeLoading: {
        info: boolean;
        colors: boolean;
        permissions: boolean;
        roles: boolean;
    };
    appInfo: AppInfoData;
    appInfoEdit: AppInfoData;
    appPermissions: AppPermission[];
    appRoles: AppRole[];
    roleDetails: AppRole;
    isRoleLoading: boolean;
    pattern: { [key: string]: string };
};

type AppInfoData = {
    id: string;
    account_name: string;
    icon: string;
    url: string;
    colors: ThemesColors;
    authorize_add_access: boolean;
};

type AppPermission = {
    id: string;
    name: string;
};

type AppRole = {
    id: string;
    name: string;
    free_duration: number;
    price: number;
    paiement_link: string;
    permissions: { id: string }[];
};

class AppInfo extends AumeeApp<AppInfoState> {
    private roleAttributesDefinition: { [key: string]: any } = {};

    constructor(props: any) {
        super('AppInfo', props, assistantSteps);

        this.state = {
            basic: (AumeeURL.extractURLParameters().basic as string) || null,
            hasBasicBeenCopied: false,
            hasConfirmedResetBasic: false,
            isEditModeForNode: {
                info: false,
                colors: false,
                permissions: {},
                roles: {},
            },
            isNodeLoading: {
                info: false,
                colors: false,
                permissions: false,
                roles: false,
            },
            appInfo: null,
            appInfoEdit: null,
            appPermissions: [],
            appRoles: [],
            roleDetails: null,
            isRoleLoading: false,
            pattern: {
                iconUrlInvalid: '',
                appName: '',
            },
        };

        AumeeURL.removeQueryParameterFromURL('basic');

        this.setNodeLoading = this.setNodeLoading.bind(this);

        this.onEditInfoClick = this.onEditInfoClick.bind(this);
        this.onIconUrlChange = this.onIconUrlChange.bind(this);
        this.onIconError = this.onIconError.bind(this);
        this.onAppNameChange = this.onAppNameChange.bind(this);
        this.verifyAppName = this.verifyAppName.bind(this);
        this.onAppUrlChange = this.onAppUrlChange.bind(this);

        this.onValidateAppInfo = this.onValidateAppInfo.bind(this);
        this.onResetBasic = this.onResetBasic.bind(this);
        this.onAuthorizeAddAccessChange =
            this.onAuthorizeAddAccessChange.bind(this);

        this.onAddPermission = this.onAddPermission.bind(this);
        this.onEditPermission = this.onEditPermission.bind(this);
        this.onDeletePermission = this.onDeletePermission.bind(this);

        this.onAddRole = this.onAddRole.bind(this);
        this.onEditRole = this.onEditRole.bind(this);
        this.onDeleteRole = this.onDeleteRole.bind(this);
        this.onDetailsRole = this.onDetailsRole.bind(this);

        this.onEditColorsClick = this.onEditColorsClick.bind(this);
        this.onColorChange = this.onColorChange.bind(this);
        this.onValidateColors = this.onValidateColors.bind(this);

        this.props.dispatch(
            setAppLoading({
                appLoading: this.appName,
            }),
        );
        this.props.dispatch(
            setAppRefreshData({
                appRefreshData: this.appName,
                appId: this.props.appId,
            }),
        );
    }

    protected async refreshAppData(): Promise<void> {
        const appIdToUse = this.props.appId;

        await this.refreshAppInfoAndColors(appIdToUse);
        if (this.props.userPermissionsOnApp.includes('permission.read')) {
            await this.refreshAppPermissions(appIdToUse);
        } else {
            this.setState({
                appPermissions: [],
            });
        }

        if (this.props.userPermissionsOnApp.includes('role.read')) {
            await this.refreshAppRoles(appIdToUse);
            this.roleAttributesDefinition = {
                price: {
                    type: 'number',
                    label: AumeeTranslator.translate('app.role.price'),
                    min: 0,
                    max: 250,
                    errorMinValue: AumeeTranslator.translate('common.too_low'),
                    errorMaxValue: AumeeTranslator.translate('common.too_high'),
                },
                free_duration: {
                    type: 'number',
                    label: AumeeTranslator.translate('app.role.free_duration'),
                    min: 0,
                    max: 30,
                    errorMinValue: AumeeTranslator.translate('common.too_low'),
                    errorMaxValue: AumeeTranslator.translate('common.too_high'),
                },
                paiement_link: {
                    type: 'string',
                    label: AumeeTranslator.translate('app.role.paiement_link'),
                    minLength: 10,
                    maxLength: 100,
                    errorMinLength:
                        AumeeTranslator.translate('common.too_short'),
                    errorMaxLength:
                        AumeeTranslator.translate('common.too_long'),
                },
                permissions: {
                    type: 'activable-list',
                    label: AumeeTranslator.translate('app.permissions'),
                    searchPlaceholder:
                        AumeeTranslator.translate('common.search'),
                    list: this.state.appPermissions
                        .map((e) => {
                            return { id: e.id, display: e.name };
                        })
                        .sort((a, b) => a.display.localeCompare(b.display)),
                    isActivableAllowed:
                        this.props.userPermissionsOnApp.includes('role.write'),
                },
            };
        } else {
            this.setState({
                appRoles: [],
            });
        }

        this.props.dispatch(setAppLoading({ appLoading: '' }));
    }

    private async refreshAppInfoAndColors(appId: string) {
        const appInfo = await AumeeAccounts.getAppInfo(appId, false);
        this.setState({
            appInfo,
            appInfoEdit: appInfo,
        });
    }

    private async refreshAppPermissions(appId: string) {
        const appPermissions: AppPermission[] = await AumeeClient.get(
            '/accounts/apps/' + appId + '/permissions',
        )
            .then((res) => res.json())
            .then((res) => res.data);

        if (
            this.roleAttributesDefinition &&
            this.props.userPermissionsOnApp.includes('role.read')
        ) {
            this.roleAttributesDefinition = {
                ...this.roleAttributesDefinition,
                permissions: {
                    ...this.roleAttributesDefinition.permissions,
                    list: appPermissions
                        .map((e) => {
                            return { id: e.id, display: e.name };
                        })
                        .sort((a, b) => a.display.localeCompare(b.display)),
                },
            };
        }

        this.setState({
            appPermissions,
        });
    }

    private async refreshAppRoles(appId: string) {
        const appRoles: AppRole[] = await AumeeClient.get(
            '/accounts/apps/' + appId + '/roles',
        )
            .then((res) => res.json())
            .then((res) =>
                res.data.map((r: any) => {
                    return { ...r, paiement_link: r.paiement_link || '' };
                }),
            );

        this.setState({
            appRoles,
            isRoleLoading: false,
            roleDetails: null,
        });
    }

    private setNodeLoading(
        node: string,
        isLoading: boolean,
        removeEditMode: boolean = true,
    ) {
        if (isLoading || !removeEditMode) {
            this.setState({
                isNodeLoading: {
                    ...this.state.isNodeLoading,
                    [node]: isLoading,
                },
            });
        } else {
            this.setState({
                isNodeLoading: {
                    ...this.state.isNodeLoading,
                    [node]: false,
                },
                isEditModeForNode: {
                    ...this.state.isEditModeForNode,
                    [node]: false,
                },
            });
        }
    }

    /** ---------------------------------------------
     *                      INFO
     * ----------------------------------------------
     */

    private onEditInfoClick() {
        this.setState({
            isEditModeForNode: {
                ...this.state.isEditModeForNode,
                info: !this.state.isEditModeForNode.info,
            },
            appInfoEdit: {
                ...this.state.appInfo,
                colors: this.state.appInfoEdit.colors,
            },
        });
    }

    private onIconUrlChange(iconUrl: string) {
        this.setState({
            appInfoEdit: {
                ...this.state.appInfoEdit,
                icon: iconUrl,
            },
            pattern: {
                ...this.state.pattern,
                iconUrlInvalid: '',
            },
        });
    }

    private onIconError() {
        this.setState({
            pattern: {
                ...this.state.pattern,
                iconUrlInvalid: '-/-',
            },
        });
    }

    private onAppNameChange(appName: string) {
        this.setState({
            appInfoEdit: {
                ...this.state.appInfoEdit,
                account_name: appName,
            },
            pattern: {
                ...this.state.pattern,
                appName: '',
            },
        });
    }

    private async verifyAppName() {
        if (
            this.state.appInfoEdit.account_name !==
                this.state.appInfo.account_name &&
            this.state.appInfoEdit.account_name.length > 3 &&
            this.state.appInfoEdit.account_name.length < 51
        ) {
            return await AumeeClient.get(
                '/accounts/exist?account_name=' +
                    this.state.appInfoEdit.account_name,
            )
                .then((res) => res.json())
                .then((res) => {
                    if (res.data.isExist) {
                        this.setState({
                            pattern: {
                                ...this.state.pattern,
                                appName: '-/-',
                            },
                        });
                    }
                    return res.data.isExist;
                });
        }

        return false;
    }

    private onAppUrlChange(appUrl: string) {
        this.setState({
            appInfoEdit: {
                ...this.state.appInfoEdit,
                url: appUrl,
            },
        });
    }

    private async onValidateAppInfo() {
        let isMissingField = false;

        this.setNodeLoading('info', true);

        const isExist = await this.verifyAppName();

        if (
            this.state.appInfoEdit.icon.length < 1 ||
            this.state.appInfoEdit.icon.length > 200 ||
            this.state.pattern.iconUrlInvalid !== ''
        ) {
            isMissingField = true;
        }

        if (
            this.state.appInfoEdit.account_name.length < 4 ||
            this.state.appInfoEdit.account_name.length > 50 ||
            isExist
        ) {
            isMissingField = true;
        }

        if (
            this.state.appInfoEdit.url &&
            this.state.appInfoEdit.url.length > 1 &&
            (this.state.appInfoEdit.url.length < 10 ||
                this.state.appInfoEdit.url.length > 100)
        ) {
            isMissingField = true;
        }

        if (isMissingField) {
            this.setNodeLoading('info', false, false);
            return;
        }

        type AttributeKeys = keyof typeof this.state.appInfoEdit;
        const attributeToUpdate = [
            'account_name',
            'icon',
            'url',
        ] as AttributeKeys[];
        const update: any = {};
        let isUpdateNeeded = false;

        for (const key of attributeToUpdate) {
            if (this.state.appInfoEdit[key] !== this.state.appInfo[key]) {
                update[key] = this.state.appInfoEdit[key];
                isUpdateNeeded = true;
            }
        }

        if (isUpdateNeeded) {
            AumeeClient.patch(
                '/accounts/apps/' + this.props.appId,
                update,
                false,
                {
                    key: 'app.info.',
                    throwError: true,
                },
            )
                .then(async () => {
                    await this.refreshAppInfoAndColors(this.props.appId);
                    if (update['account_name']) {
                        await AumeeAnalytics.onAumeeAnalyticsInit();
                    }
                    this.setNodeLoading('info', false);
                })
                .catch((error) => {
                    this.setNodeLoading('info', false, false);
                });
        } else {
            this.setNodeLoading('info', false);
        }
    }

    private async onResetBasic() {
        this.setNodeLoading('info', true);

        const newBasic = await AumeeClient.post(
            '/accounts/apps/' + this.props.appId + '/reset',
        )
            .then((res) => res.json())
            .then((res) => res.data.basic);

        this.setState({
            basic: newBasic,
            hasBasicBeenCopied: false,
            hasConfirmedResetBasic: false,
            isNodeLoading: {
                ...this.state.isNodeLoading,
                info: false,
            },
        });
    }

    private async onAuthorizeAddAccessChange(authorize: boolean) {
        this.setNodeLoading('info', true);

        await AumeeClient.patch(
            '/accounts/apps/' + this.props.appId + '/accesses/authorize',
            {
                authorize,
            },
        );

        await this.refreshAppInfoAndColors(this.props.appId);

        this.setNodeLoading('info', false);
    }

    /** ---------------------------------------------
     *                      PERMISSIONS
     * ----------------------------------------------
     */

    private onAddPermission(newPermission: string) {
        AumeeClient.post(
            '/accounts/apps/' + this.props.appId + '/permissions',
            { name: newPermission },
            false,
            { key: 'app.add_permissions.', withStatus: true },
        ).then(() => {
            this.refreshAppPermissions(this.props.appId);
        });
    }

    private onEditPermission(oldPermission: string, newPermission: string) {
        const permissionId = this.state.appPermissions.find(
            (perm) => perm.name === oldPermission,
        )?.id;

        AumeeClient.patch(
            '/accounts/apps/' +
                this.props.appId +
                '/permissions/' +
                permissionId,
            { name: newPermission },
            false,
            { key: 'app.update_permissions.', withStatus: true },
        ).then(() => {
            this.refreshAppPermissions(this.props.appId);
        });
    }

    private onDeletePermission(permission: string) {
        const permissionId = this.state.appPermissions.find(
            (perm) => perm.name === permission,
        )?.id;

        AumeeClient.delete(
            '/accounts/apps/' +
                this.props.appId +
                '/permissions/' +
                permissionId,
            false,
            { key: 'app.delete_permissions.', withStatus: true },
        ).then(() => {
            this.refreshAppPermissions(this.props.appId);
        });
    }

    /** ---------------------------------------------
     *                      ROLES
     * ----------------------------------------------
     */

    private onAddRole(newRole: string) {
        AumeeClient.post(
            '/accounts/apps/' + this.props.appId + '/roles',
            {
                name: newRole,
            },
            false,
            { key: 'app.add_permissions.', withStatus: true },
        ).then(() => {
            this.refreshAppRoles(this.props.appId);
        });
    }

    private onEditRole(oldRole: string, newRole: any) {
        const roleId = this.state.appRoles.find(
            (rol) => rol.name === oldRole,
        )?.id;

        if (!newRole.name) {
            this.setState({
                isRoleLoading: true,
            });
        }

        if (newRole.permissions) {
            newRole.permissions = newRole.permissions.map((p: any) => p.id);
        }

        AumeeClient.patch(
            '/accounts/apps/' + this.props.appId + '/roles/' + roleId,
            newRole,
            false,
            { key: 'app.update_roles.', withStatus: true },
        ).then(() => {
            this.refreshAppRoles(this.props.appId);
        });
    }

    private onDeleteRole(role: string) {
        const roleId = this.state.appRoles.find((rol) => rol.name === role)?.id;

        AumeeClient.delete(
            '/accounts/apps/' + this.props.appId + '/roles/' + roleId,
            false,
            { key: 'app.delete_roles.', withStatus: true },
        ).then(() => {
            this.refreshAppRoles(this.props.appId);
        });
    }

    private onDetailsRole(role: string) {
        const roleFound = this.state.appRoles.find((rol) => rol.name === role);

        this.setState({
            roleDetails: roleFound,
        });
    }

    /** ---------------------------------------------
     *                      COLORS
     * ----------------------------------------------
     */

    private onEditColorsClick() {
        this.setState({
            isEditModeForNode: {
                ...this.state.isEditModeForNode,
                colors: !this.state.isEditModeForNode.colors,
            },
            appInfoEdit: {
                ...this.state.appInfo,
                icon: this.state.appInfoEdit.icon,
                account_name: this.state.appInfoEdit.account_name,
                url: this.state.appInfoEdit.url,
            },
        });
    }

    private onColorChange(
        theme: ThemeName,
        color: ColorName,
        colorValue: Color,
    ) {
        this.setState({
            appInfoEdit: {
                ...this.state.appInfoEdit,
                colors: {
                    ...this.state.appInfoEdit.colors,
                    [theme]: {
                        ...this.state.appInfoEdit.colors[theme],
                        [color]: colorValue,
                    },
                },
            },
        });
    }

    private onValidateColors() {
        const update: any = {};
        let isUpdateNeeded = false;
        update['colors'] = {};
        if (
            this.state.appInfoEdit.colors.light !==
            this.state.appInfo.colors.light
        ) {
            update['colors']['light'] = this.state.appInfoEdit.colors.light;
            isUpdateNeeded = true;
        }

        if (
            this.state.appInfoEdit.colors.dark !==
            this.state.appInfo.colors.dark
        ) {
            update['colors']['dark'] = this.state.appInfoEdit.colors.dark;
            isUpdateNeeded = true;
        }

        if (isUpdateNeeded) {
            this.setNodeLoading('colors', true);
            AumeeClient.patch(
                '/accounts/apps/' + this.props.appId,
                update,
                false,
                {
                    key: 'app.info.',
                    throwError: true,
                },
            )
                .then(async () => {
                    const appInfo = await AumeeAccounts.getAppInfo(
                        this.props.appId,
                        false,
                    );
                    this.setState({
                        appInfo,
                        appInfoEdit: appInfo,
                    });
                    this.setNodeLoading('colors', false);
                })
                .catch(() => {
                    this.setNodeLoading('colors', false, false);
                });
        } else {
            this.onEditColorsClick();
        }
    }

    render() {
        const { userPermissionsOnApp, isMobileScreen } = this.props;

        const {
            basic,
            hasBasicBeenCopied,
            hasConfirmedResetBasic,
            isEditModeForNode,
            isNodeLoading,
            appInfo,
            appInfoEdit,
            appPermissions,
            appRoles,
            roleDetails,
            isRoleLoading,
            pattern,
        } = this.state;

        if (!appInfo) {
            return null;
        }

        type ColorKeys = keyof typeof appInfo.colors.light;

        const colorsKeys = Object.keys(appInfo.colors.light) as ColorKeys[];

        const orientation = isMobileScreen ? 'horizontal' : 'vertical';

        return (
            <div id={this.id} className="app-info page">
                {this.getAssistant()}
                <Container
                    fatherId={this.id}
                    usage="info"
                    color="secondary"
                    colorVariant="light"
                    isLoading={isNodeLoading.info}
                >
                    <EditableElement
                        fatherId={this.id}
                        usage="info"
                        isDeleteDisplayed={false}
                        isDetailsDisplayed={false}
                        isEditDisplayed={userPermissionsOnApp.includes(
                            'app.write',
                        )}
                        isEditing={isEditModeForNode.info}
                        onEdit={this.onEditInfoClick}
                        onCancelEdit={this.onEditInfoClick}
                        onValidateEdit={this.onValidateAppInfo}
                    >
                        <div>
                            {!isEditModeForNode.info && (
                                <div className="data-display">
                                    <Tooltip
                                        fatherId={this.id}
                                        usage="app-icon"
                                        label={appInfo.icon}
                                        position="vertical"
                                    >
                                        <img
                                            className="icon"
                                            src={appInfo.icon}
                                            alt="App icon"
                                        />
                                    </Tooltip>
                                    <Spacer
                                        size={
                                            orientation === 'horizontal'
                                                ? 'tiny'
                                                : 'medium'
                                        }
                                        direction={orientation}
                                    />
                                    <div
                                        className="spans"
                                        style={{
                                            width:
                                                orientation === 'horizontal'
                                                    ? '100%'
                                                    : '60%',
                                        }}
                                    >
                                        <span
                                            id="app_info_account-name"
                                            className="attribute ellipsis"
                                        >
                                            {appInfo.account_name}
                                        </span>
                                        {appInfo.url && <Spacer size="small" />}
                                        {appInfo.url && (
                                            <span
                                                id="app_info_url"
                                                className="attribute ellipsis"
                                            >
                                                🌐 {appInfo.url}
                                            </span>
                                        )}
                                        {(basic ||
                                            userPermissionsOnApp.includes(
                                                'password.reset',
                                            )) && <Spacer size="small" />}
                                        {basic && (
                                            <Container
                                                fatherId={this.id}
                                                usage="basic"
                                                color="warning"
                                                padding="medium"
                                            >
                                                {!hasBasicBeenCopied && (
                                                    <div className="basic">
                                                        <span className="ellipsis">
                                                            {basic}
                                                        </span>
                                                        <Spacer
                                                            direction="vertical"
                                                            size="tiny"
                                                        />
                                                        <Button
                                                            fatherId={this.id}
                                                            usage="copy-basic"
                                                            label={AumeeTranslator.translate(
                                                                'common.copy',
                                                            )}
                                                            labelSize="small"
                                                            style="secondary"
                                                            avoidBlurAfterClick={
                                                                true
                                                            }
                                                            onClick={() => {
                                                                window.navigator.clipboard
                                                                    .writeText(
                                                                        basic,
                                                                    )
                                                                    .then(() =>
                                                                        this.setState(
                                                                            {
                                                                                hasBasicBeenCopied:
                                                                                    true,
                                                                            },
                                                                        ),
                                                                    )
                                                                    .catch(() =>
                                                                        this.setState(
                                                                            {
                                                                                hasBasicBeenCopied:
                                                                                    true,
                                                                            },
                                                                        ),
                                                                    );
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                                {hasBasicBeenCopied && (
                                                    <div className="basic">
                                                        <span>
                                                            {AumeeTranslator.translate(
                                                                'common.done',
                                                            )}
                                                        </span>
                                                        <Spacer
                                                            direction="vertical"
                                                            size="tiny"
                                                        />
                                                        <Button
                                                            fatherId={this.id}
                                                            usage="validate-copy"
                                                            icon="validate"
                                                            style="underlined"
                                                            iconSize="16"
                                                            onClick={() =>
                                                                this.setState({
                                                                    basic: null,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </Container>
                                        )}
                                        {!basic &&
                                            userPermissionsOnApp.includes(
                                                'password.reset',
                                            ) && (
                                                <Tooltip
                                                    fatherId={this.id}
                                                    usage="reset-basic"
                                                    label={AumeeTranslator.translate(
                                                        'app.basic.reset.description',
                                                    )}
                                                    position="vertical"
                                                    isVisible={
                                                        hasConfirmedResetBasic
                                                    }
                                                >
                                                    {!hasConfirmedResetBasic && (
                                                        <Button
                                                            fatherId={this.id}
                                                            usage="reset-basic"
                                                            label={AumeeTranslator.translate(
                                                                'app.basic.reset',
                                                            )}
                                                            icon="refresh"
                                                            iconSide="right"
                                                            style="warning"
                                                            labelSize="small"
                                                            onClick={() =>
                                                                this.setState({
                                                                    hasConfirmedResetBasic:
                                                                        true,
                                                                })
                                                            }
                                                        />
                                                    )}
                                                    {hasConfirmedResetBasic && (
                                                        <div className="aumee--flex-center">
                                                            <Button
                                                                fatherId={
                                                                    this.id
                                                                }
                                                                usage="cancel-reset-basic"
                                                                icon="return"
                                                                style="secondary"
                                                                iconSize="16"
                                                                labelSize="small"
                                                                onClick={() =>
                                                                    this.setState(
                                                                        {
                                                                            hasConfirmedResetBasic:
                                                                                false,
                                                                        },
                                                                    )
                                                                }
                                                            />
                                                            <Spacer
                                                                direction="vertical"
                                                                size="small"
                                                            />
                                                            <Button
                                                                fatherId={
                                                                    this.id
                                                                }
                                                                usage="confirm-reset-basic"
                                                                icon="validate"
                                                                style="warning"
                                                                iconSize="16"
                                                                labelSize="small"
                                                                onClick={
                                                                    this
                                                                        .onResetBasic
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </Tooltip>
                                            )}
                                        {userPermissionsOnApp.includes(
                                            'access.write',
                                        ) && <Spacer size="small" />}
                                        {userPermissionsOnApp.includes(
                                            'access.write',
                                        ) && (
                                            <Tooltip
                                                fatherId={this.id}
                                                usage="authorize-add-access"
                                                label={AumeeTranslator.translate(
                                                    'app.authorize_add_access.description',
                                                )}
                                                position="vertical"
                                            >
                                                <Switch
                                                    fatherId={this.id}
                                                    usage="authorize-add-access"
                                                    label={AumeeTranslator.translate(
                                                        'app.authorize_add_access',
                                                    )}
                                                    checked={
                                                        appInfo.authorize_add_access
                                                    }
                                                    onValueChange={
                                                        this
                                                            .onAuthorizeAddAccessChange
                                                    }
                                                />
                                            </Tooltip>
                                        )}
                                    </div>
                                </div>
                            )}
                            {isEditModeForNode.info && (
                                <EditAppInfo
                                    fatherId={this.id}
                                    iconUrl={appInfoEdit.icon}
                                    name={appInfoEdit.account_name}
                                    url={appInfoEdit.url}
                                    orientation={orientation}
                                    pattern={pattern}
                                    onIconUrlChange={this.onIconUrlChange}
                                    onIconError={this.onIconError}
                                    onNameChange={this.onAppNameChange}
                                    verifyAppName={this.verifyAppName}
                                    onUrlChange={this.onAppUrlChange}
                                />
                            )}
                        </div>
                    </EditableElement>
                </Container>
                {(userPermissionsOnApp.includes('permission.read') ||
                    userPermissionsOnApp.includes('permission.read')) && (
                    <Spacer size="large" />
                )}
                <div className="accesses">
                    {userPermissionsOnApp.includes('permission.read') && (
                        <Container
                            fatherId={this.id}
                            usage="permissions"
                            color="secondary"
                            colorVariant="light"
                        >
                            <EditableList
                                fatherId={this.id}
                                usage="permissions"
                                label={AumeeTranslator.translate(
                                    'app.permissions',
                                )}
                                list={appPermissions.map((perm) => perm.name)}
                                onElementAdded={this.onAddPermission}
                                onElementUpdated={this.onEditPermission}
                                onElementDeleted={this.onDeletePermission}
                                isEditAllowed={userPermissionsOnApp.includes(
                                    'permission.write',
                                )}
                                patternError="^(?!\.|_){1}[a-z._]*[^._]{1}$"
                                patternErrorMessage={AumeeTranslator.translate(
                                    'common.wrong_format',
                                )}
                                errorAlreadyExists={AumeeTranslator.translate(
                                    'common.already_exists',
                                )}
                                elementMinLenght={2}
                                errorMinLenght={AumeeTranslator.translate(
                                    'common.too_short',
                                )}
                                elementMaxLenght={50}
                                errorMaxLenght={AumeeTranslator.translate(
                                    'common.too_long',
                                )}
                            />
                        </Container>
                    )}
                    {userPermissionsOnApp.includes('role.read') && (
                        <Container
                            fatherId={this.id}
                            usage="roles"
                            color="secondary"
                            colorVariant="light"
                        >
                            {!roleDetails && (
                                <EditableList
                                    fatherId={this.id}
                                    usage="roles"
                                    label={AumeeTranslator.translate(
                                        'app.roles',
                                    )}
                                    list={appRoles.map((role) => role.name)}
                                    isEditAllowed={userPermissionsOnApp.includes(
                                        'role.write',
                                    )}
                                    onElementAdded={this.onAddRole}
                                    onElementUpdated={(
                                        oldElement,
                                        newElement,
                                    ) =>
                                        this.onEditRole(oldElement, {
                                            name: newElement,
                                        })
                                    }
                                    onElementDeleted={this.onDeleteRole}
                                    isDetailsUsed={true}
                                    onDetails={this.onDetailsRole}
                                    patternError="^(?!_){1}[a-z_]*[a-z]{1}$"
                                    patternErrorMessage={AumeeTranslator.translate(
                                        'common.wrong_format',
                                    )}
                                    errorAlreadyExists={AumeeTranslator.translate(
                                        'common.already_exists',
                                    )}
                                    elementMinLenght={2}
                                    errorMinLenght={AumeeTranslator.translate(
                                        'common.too_short',
                                    )}
                                    elementMaxLenght={30}
                                    errorMaxLenght={AumeeTranslator.translate(
                                        'common.too_long',
                                    )}
                                />
                            )}
                            {roleDetails && (
                                <EditableObject
                                    fatherId={this.id}
                                    usage="roles"
                                    object={roleDetails}
                                    attributesDefinition={
                                        this.roleAttributesDefinition
                                    }
                                    totalLabel={AumeeTranslator.translate(
                                        'common.total',
                                    )}
                                    isEditAllowed={userPermissionsOnApp.includes(
                                        'role.write',
                                    )}
                                    isLoading={isRoleLoading}
                                    onCancel={() => {
                                        this.setState({
                                            roleDetails: null,
                                        });
                                    }}
                                    onValidate={(newRole) => {
                                        this.onEditRole(
                                            roleDetails.name,
                                            newRole,
                                        );
                                    }}
                                />
                            )}
                        </Container>
                    )}
                </div>
                <Spacer size="large" />
                <Container
                    fatherId={this.id}
                    usage="colors"
                    color="secondary"
                    colorVariant="light"
                    isLoading={isNodeLoading.colors}
                >
                    <EditableElement
                        fatherId={this.id}
                        usage="colors"
                        isDeleteDisplayed={false}
                        isDetailsDisplayed={false}
                        isEditDisplayed={userPermissionsOnApp.includes(
                            'app.write',
                        )}
                        isEditing={isEditModeForNode.colors}
                        onEdit={this.onEditColorsClick}
                        onCancelEdit={this.onEditColorsClick}
                        onValidateEdit={this.onValidateColors}
                    >
                        <div>
                            {!isEditModeForNode.colors && (
                                <div className="app-colors">
                                    {colorsKeys.map((colorKey, index) => {
                                        return (
                                            <div className="app-color-section">
                                                <div className="color">
                                                    <span>
                                                        {AumeeTranslator.translate(
                                                            'app.' + colorKey,
                                                        )}
                                                    </span>
                                                    <Spacer size="small" />
                                                    <ShadeColorDisplay
                                                        fatherId={this.id}
                                                        usage={
                                                            colorKey + '-light'
                                                        }
                                                        label={AumeeTranslator.translate(
                                                            'app.light',
                                                        )}
                                                        color={
                                                            appInfoEdit.colors
                                                                .light[colorKey]
                                                        }
                                                    />
                                                    <Spacer size="small" />
                                                    <ShadeColorDisplay
                                                        fatherId={this.id}
                                                        usage={
                                                            colorKey + '-dark'
                                                        }
                                                        label={AumeeTranslator.translate(
                                                            'app.dark',
                                                        )}
                                                        color={
                                                            appInfoEdit.colors
                                                                .dark[colorKey]
                                                        }
                                                    />
                                                </div>
                                                {index + 1 <
                                                    colorsKeys.length && (
                                                    <Divider
                                                        direction={orientation}
                                                        color="primary"
                                                        margin="medium"
                                                    />
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            {isEditModeForNode.colors && (
                                <div className="app-colors">
                                    {colorsKeys.map((colorKey, index) => {
                                        return (
                                            <div className="app-color-section">
                                                <div className="color">
                                                    <span>
                                                        {AumeeTranslator.translate(
                                                            'app.' + colorKey,
                                                        )}
                                                    </span>
                                                    <Spacer size="small" />
                                                    <ShadeColorPicker
                                                        fatherId={this.id}
                                                        usage={
                                                            colorKey + '-light'
                                                        }
                                                        label={AumeeTranslator.translate(
                                                            'app.light',
                                                        )}
                                                        color={
                                                            appInfoEdit.colors
                                                                .light[colorKey]
                                                                .value
                                                        }
                                                        shade={
                                                            appInfoEdit.colors
                                                                .light[colorKey]
                                                                .shade
                                                        }
                                                        onColorChange={(
                                                            color: Color,
                                                        ) =>
                                                            this.onColorChange(
                                                                'light',
                                                                colorKey,
                                                                color,
                                                            )
                                                        }
                                                    />
                                                    <Spacer size="small" />
                                                    <ShadeColorPicker
                                                        fatherId={this.id}
                                                        usage={
                                                            colorKey + '-dark'
                                                        }
                                                        label={AumeeTranslator.translate(
                                                            'app.dark',
                                                        )}
                                                        color={
                                                            appInfoEdit.colors
                                                                .dark[colorKey]
                                                                .value
                                                        }
                                                        shade={
                                                            appInfoEdit.colors
                                                                .dark[colorKey]
                                                                .shade
                                                        }
                                                        onColorChange={(
                                                            color: Color,
                                                        ) =>
                                                            this.onColorChange(
                                                                'dark',
                                                                colorKey,
                                                                color,
                                                            )
                                                        }
                                                    />
                                                </div>
                                                {index + 1 <
                                                    colorsKeys.length && (
                                                    <Divider
                                                        direction={orientation}
                                                        color="primary"
                                                        margin="medium"
                                                    />
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </EditableElement>
                </Container>
            </div>
        );
    }
}

export default connect()(AppInfo);
