import './LogThisOut.css';
import { AumeeApp } from '../AumeeApp';
import { connect } from 'react-redux';
import { setAppLoading } from '../../store/slices/app-loading';
import { setAppRefreshData } from '../../store/slices/app-refresh-data';
import {
    Assistant,
    Button,
    Container,
    DateTimePicker,
    Select,
    Spacer,
    Table,
} from '@dyrloz/aumee-design';
import { AumeeClient, AumeeTranslator, AumeeURL } from '@dyrloz/aumee-client';
import assistantSteps from './log-this-out.assistant';

type LogThisOutState = {
    logs: Log[];
    totalLogs: number;
    filters: { [key: string]: number | string };
    range: number;
    page: number;
    pagesLoaded: number[];
    sort: 'asc' | 'desc';
    sortBy: string;
    isLogsLoading: boolean;
};

type Log = {
    date: string;
    class_name: string;
    trace_id: string;
    event: string;
    type: string;
    data: any;
};

class LogThisOut extends AumeeApp<LogThisOutState> {
    private typeValues = [
        {
            value: 'error',
            text: 'Error',
        },
        {
            value: 'status',
            text: 'Status',
        },
        {
            value: 'analytics',
            text: 'Analytics',
        },
        {
            value: 'info',
            text: 'Info',
        },
        {
            value: 'debug',
            text: 'Debug',
        },
    ];
    private sessionIds: { value: string; text: string }[] = [];
    private traceIds: { value: string; text: string }[] = [];
    private classNames: { value: string; text: string }[] = [];
    private logHeaders = {
        type: AumeeTranslator.translate('logs.type'),
        trace_id: AumeeTranslator.translate('logs.trace_id'),
        date: AumeeTranslator.translate('logs.date'),
        class_name: AumeeTranslator.translate('logs.class_name'),
        event: AumeeTranslator.translate('logs.event'),
        'data.status_code': AumeeTranslator.translate('logs.status_code'),
        data: AumeeTranslator.translate('logs.data'),
    };
    private columnWidth = {
        type: {
            percent: 10,
            minWidth: 94,
        },
        trace_id: {
            percent: 15,
            minWidth: 130,
        },
        date: {
            percent: 15,
            minWidth: 130,
        },
        class_name: {
            percent: 10,
            minWidth: 94,
        },
        event: {
            percent: 10,
            minWidth: 94,
        },
        'data.status_code': {
            percent: 5,
            minWidth: 47,
        },
        data: {
            percent: 10,
            minWidth: 200,
        },
    };

    constructor(props: any) {
        super('LogThisOut', props, assistantSteps);

        // GET VALUE FROM URL OR DEFAULT ONE, THEN SET IN URL
        const queryParameters = AumeeURL.extractURLParameters();

        const actualDate = new Date();

        const filters: { [key: string]: number | string } = {
            type: (queryParameters.type as string) || 'all',
            session_id: (queryParameters.session_id as string) || 'all',
            trace_id: (queryParameters.trace_id as string) || 'all',
            start_date:
                (queryParameters.start_date as string) ||
                new Date(
                    actualDate.getFullYear(),
                    actualDate.getMonth(),
                    actualDate.getDate(),
                ).toISOString(),
            end_date:
                (queryParameters.end_date as string) ||
                actualDate.toISOString(),
            class_name: (queryParameters.class_name as string) || 'all',
            event: (queryParameters.event as string) || 'all',
        };

        this.state = {
            logs: [],
            totalLogs: 0,
            filters: filters,
            page: 1,
            range: 25,
            pagesLoaded: [1],
            sort: 'asc',
            sortBy: (queryParameters.sort_by as string) || 'date',
            isLogsLoading: false,
        };

        for (const key in filters) {
            if (filters[key] !== 'all') {
                AumeeURL.addQueryParameterToURL(key + '=' + filters[key]);
            }
        }

        AumeeURL.addQueryParameterToURL('range=' + this.state.range);
        AumeeURL.addQueryParameterToURL('page=' + this.state.page);
        AumeeURL.addQueryParameterToURL('sort=' + this.state.sort);
        AumeeURL.addQueryParameterToURL('sort_by=' + this.state.sortBy);

        this.onPageChange = this.onPageChange.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onRefreshClicked = this.onRefreshClicked.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.onRangeChange = this.onRangeChange.bind(this);

        this.props.dispatch(
            setAppLoading({
                appLoading: this.appName,
            }),
        );
        this.props.dispatch(
            setAppRefreshData({
                appRefreshData: this.appName,
                appId: this.props.appId,
            }),
        );
    }

    protected async refreshAppData() {
        this.setState({
            isLogsLoading: true,
        });
        const requestParameters = AumeeURL.getURLParametersQuery();
        if (
            requestParameters.indexOf('start_date') === -1 ||
            requestParameters.indexOf('end_date') === -1
        )
            return;

        const countLogs = await AumeeClient.get(
            '/logs/count' +
                requestParameters.replace(
                    /&range=[0-9]+&page=[0-9]+&sort=(asc|desc)&sort_by=[a-z_.]+/g,
                    '',
                ),
        )
            .then((res) => res.json())
            .then((res) => res.data);

        const logs = await AumeeClient.get('/logs' + requestParameters)
            .then((res) => res.json())
            .then((res) => res.data);

        this.refreshFiltersValues(logs);

        this.setState({
            logs: this.formatLogs(logs),
            totalLogs: countLogs.total,
        });

        this.props.dispatch(setAppLoading({ appLoading: '' }));
        this.setState({
            isLogsLoading: false,
        });
    }

    private formatLogs(logs: any): Log[] {
        return logs.map((log: any) => {
            const date = new Date(log.date);
            return {
                date:
                    date.toLocaleString() +
                    '.' +
                    (date.getMilliseconds() < 100
                        ? date.getMilliseconds() < 10
                            ? '00'
                            : '0'
                        : '') +
                    date.getMilliseconds(),
                type: log.type,
                trace_id: log.trace_id,
                class_name: log.class_name
                    .replace(/([A-Z])/g, ' $1')
                    .trim()
                    .replace(/ /g, '<br>'),
                event: log.event.replace(/\//g, '<br>/'),
                data: log.data,
            };
        });
    }

    private addOptionAll(values: { value: string; text: string }[]) {
        const ArrayWithAllOption = [
            {
                value: 'all',
                text: AumeeTranslator.translate('logs.all'),
            },
        ];

        return ArrayWithAllOption.concat(values);
    }

    private refreshFiltersValues(logs: Log[], concatWithPrevious = false) {
        this.sessionIds = this.extractUniqueValuesForFilter(
            logs,
            'trace_id',
            concatWithPrevious ? this.sessionIds : [],
            (value: string) => value.split('-')[0],
        );
        this.traceIds = this.extractUniqueValuesForFilter(
            logs,
            'trace_id',
            concatWithPrevious ? this.traceIds : [],
        );
        this.classNames = this.extractUniqueValuesForFilter(
            logs,
            'class_name',
            concatWithPrevious ? this.classNames : [],
        );
    }

    private extractUniqueValuesForFilter(
        logs: any,
        attribute: string,
        previousValues: { value: string; text: string }[],
        transform = (value: string) => value,
    ) {
        const totalValues = logs.reduce(
            (total: { value: string; text: string }[], log: any) => {
                const value: string = transform(log[attribute]);
                const totalValueFound = total.find((v) => v.text === value);
                if (!totalValueFound) {
                    total.push({
                        value: value.replace(/[ ]/g, ''),
                        text: value,
                    });
                }
                return total;
            },
            [],
        );

        return previousValues
            .reduce(
                (
                    total: { value: string; text: string }[],
                    filterElement: { value: string; text: string },
                ) => {
                    const totalValueFound = total.find(
                        (v) => v.value === filterElement.value,
                    );
                    if (!totalValueFound) {
                        total.push(filterElement);
                    }
                    return total;
                },
                totalValues,
            )
            .sort(
                (
                    a: { value: string; text: string },
                    b: { value: string; text: string },
                ) => (a.value > b.value ? 1 : -1),
            );
    }

    private onFilterChange(attribute: string, value: string | number) {
        if (value !== 'all') {
            AumeeURL.addQueryParameterToURL(attribute + '=' + value);
        } else {
            AumeeURL.removeQueryParameterFromURL(attribute);
        }
        this.setState({
            filters: {
                ...this.state.filters,
                [attribute]: value,
            },
        });
    }

    private async onPageChange(newPage: number) {
        AumeeURL.addQueryParameterToURL('page=' + newPage);

        let logsConcat: Log[] = this.state.logs;
        if (!this.state.pagesLoaded.includes(newPage)) {
            const requestParameters = AumeeURL.getURLParametersQuery();
            this.setState({
                isLogsLoading: true,
            });
            let logs: Log[] = await AumeeClient.get('/logs' + requestParameters)
                .then((res) => res.json())
                .then((res) => res.data);

            this.refreshFiltersValues(logs, true);

            logs = this.formatLogs(logs);

            if (newPage < this.state.page) {
                logsConcat = logs.concat(logsConcat);
            } else if (newPage > this.state.page) {
                logsConcat = logsConcat.concat(logs);
            } else {
                logsConcat = logs;
            }
            this.state.pagesLoaded.push(newPage);
        }

        this.setState({
            page: newPage,
            logs: logsConcat,
            isLogsLoading: false,
        });
    }

    private onSortChange(attribute: string, sortDirection: -1 | 1) {
        const sort = sortDirection > 0 ? 'asc' : 'desc';

        this.setState({
            sort,
            sortBy: attribute,
            pagesLoaded: [],
        });
        AumeeURL.addQueryParameterToURL('sort=' + sort);
        AumeeURL.addQueryParameterToURL('sort_by=' + attribute);

        setTimeout(() => this.onPageChange(1), 100);
    }

    private onRangeChange(newRange: number) {
        if (newRange === this.state.range) return;
        this.setState({
            range: newRange,
        });
        AumeeURL.addQueryParameterToURL('range=' + newRange);
        this.onRefreshClicked();
    }

    private onRefreshClicked() {
        AumeeURL.addQueryParameterToURL('page=1');
        this.setState({
            page: 1,
            pagesLoaded: [1],
        });
        this.refreshAppData();
    }

    render() {
        const { menuPosition } = this.props;
        const {
            logs,
            totalLogs,
            filters,
            page,
            range,
            pagesLoaded,
            isLogsLoading,
            sortBy,
        } = this.state;

        return (
            <div id={this.id} className="log-this-out page">
                {this.getAssistant(
                    (step) =>
                        logs.length > 0 ||
                        (step.id !==
                            'log-this-out_table_logs_button_display-total' &&
                            step.id !== 'log-this-out_table_logs_row-1'),
                )}
                <Container fatherId={this.id} usage="inputs">
                    <div className="inputs">
                        <DateTimePicker
                            fatherId={this.id}
                            usage="start-date"
                            type="datetime-local"
                            step="milliseconds"
                            value={filters['start_date'] as string}
                            placeholder={AumeeTranslator.translate(
                                'logs.start_date',
                            )}
                            onValueChange={(date: string) => {
                                this.onFilterChange('start_date', date);
                            }}
                        />
                        <DateTimePicker
                            fatherId={this.id}
                            usage="end-date"
                            type="datetime-local"
                            step="milliseconds"
                            value={filters['end_date'] as string}
                            min={filters['start_date'] as string}
                            placeholder={AumeeTranslator.translate(
                                'logs.end_date',
                            )}
                            onValueChange={(date: string) => {
                                this.onFilterChange('end_date', date);
                            }}
                        />
                        <Select
                            fatherId={this.id}
                            usage="type"
                            size="medium"
                            defaultValue={
                                this.typeValues.length === 0
                                    ? 'all'
                                    : (filters['type'] as string).split(',')[0]
                            }
                            placeholder={AumeeTranslator.translate('logs.type')}
                            isInfoTextDisplayed={true}
                            useValueAsOptionId={true}
                            values={this.addOptionAll(this.typeValues)}
                            withInput={true}
                            onValueChange={(type: string) => {
                                this.onFilterChange('type', type);
                            }}
                        />
                        <Select
                            fatherId={this.id}
                            usage="session"
                            size="medium"
                            defaultValue={
                                this.sessionIds.length === 0
                                    ? 'all'
                                    : (filters['session_id'] as string)
                            }
                            placeholder={AumeeTranslator.translate(
                                'logs.session_id',
                            )}
                            isInfoTextDisplayed={true}
                            values={this.addOptionAll(this.sessionIds)}
                            withInput={true}
                            onValueChange={(sessionId: string) => {
                                this.onFilterChange('session_id', sessionId);
                            }}
                        />
                        <Select
                            fatherId={this.id}
                            usage="trace"
                            size="long"
                            defaultValue={
                                this.traceIds.length === 0
                                    ? 'all'
                                    : (filters['trace_id'] as string)
                            }
                            placeholder={AumeeTranslator.translate(
                                'logs.trace_id',
                            )}
                            isInfoTextDisplayed={true}
                            values={this.addOptionAll(this.traceIds)}
                            withInput={true}
                            onValueChange={(traceId: string) => {
                                this.onFilterChange('trace_id', traceId);
                            }}
                        />
                        <Select
                            fatherId={this.id}
                            usage="class"
                            size="medium"
                            defaultValue={
                                this.classNames.length === 0
                                    ? 'all'
                                    : (filters['class_name'] as string)
                            }
                            placeholder={AumeeTranslator.translate(
                                'logs.class_name',
                            )}
                            isInfoTextDisplayed={true}
                            values={this.addOptionAll(this.classNames)}
                            withInput={true}
                            onValueChange={(className: string) => {
                                this.onFilterChange('class_name', className);
                            }}
                        />
                        <Button
                            fatherId={this.id}
                            usage="refresh"
                            icon="refresh"
                            iconSize="24"
                            onClick={this.onRefreshClicked}
                        />
                    </div>
                </Container>
                <Spacer />
                <Container fatherId={this.id} usage="table" padding="epic">
                    <Table
                        fatherId={this.id}
                        usage="logs"
                        headers={this.logHeaders}
                        values={logs}
                        widths={this.columnWidth}
                        useValueAsSpanClassName={{
                            type: true,
                            'data.status_code': true,
                        }}
                        useAttributeToSort={{
                            type: true,
                            trace_id: true,
                            date: true,
                            class_name: true,
                            event: true,
                            'data.status_code': true,
                        }}
                        emptyMessage={AumeeTranslator.translate(
                            'logs.not_found',
                        )}
                        isLoading={isLogsLoading}
                        onPageChange={this.onPageChange}
                        range={range}
                        page={page}
                        total={totalLogs}
                        sort={sortBy}
                        sortDirection={1}
                        onSortChange={this.onSortChange}
                        onRangeChange={this.onRangeChange}
                    />
                </Container>
            </div>
        );
    }
}

export default connect()(LogThisOut);
