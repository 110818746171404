import { Direction, Side } from '@dyrloz/aumee-design';

export default [
    {
        id: 'notificator_select_template',
        position: 'vertical' as Direction,
        text: 'notificator.template.select',
        maxWidth: 200,
    },
    {
        id: 'notificator_button_new-template',
        position: 'vertical' as Direction,
        text: 'notificator.template.add',
        maxWidth: 200,
    },
    {
        id: 'notificator_editable-element_template-name',
        position: 'vertical' as Direction,
        text: 'notificator.template.name',
        maxWidth: 250,
    },
    {
        id: 'notificator_select-locale',
        position: 'vertical' as Direction,
        text: 'notificator.template.locale',
        maxWidth: 200,
    },
    {
        id: 'notificator_switch_use-app-color',
        position: 'vertical' as Direction,
        text: 'notificator.template.use_app_color',
        maxWidth: 200,
    },
    {
        id: 'notificator_switch_use-header-footer',
        position: 'vertical' as Direction,
        text: 'notificator.template.use_header_footer',
        maxWidth: 200,
    },
    {
        id: 'notificator_container_locale-mail',
        position: 'vertical' as Direction,
        text: 'notificator.template.locale_mail',
        maxWidth: 300,
    },
];
