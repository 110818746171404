import { Icon } from '@dyrloz/aumee-design';
import { IIconProps } from '@dyrloz/aumee-design/dist/components/icons/Icon';

/**
 * DataIcon displays bar charts.
 */
export class DataIcon extends Icon {
    constructor(props: any) {
        super('DataIcon', props);
    }

    render(): JSX.Element {
        let {
            color = 'primary',
            size = '16',
            side = null,
            colorVariant = 'light',
            rotation = 0,
        }: IIconProps = this.props;
        return (
            <svg
                id={this.id}
                xmlns="http://www.w3.org/2000/svg"
                width={size}
                height={size}
                style={this.createFillStyle(color, colorVariant, rotation)}
                className={`aumee--icon ${!side ? '' : `aumee--icon--${side}`}`}
                viewBox="0 0 16 16"
            >
                <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1z" />
            </svg>
        );
    }
}
