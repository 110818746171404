import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

// Define a type for the slice state
interface CheckAppToRefreshState {
    value: string;
}

// Define the initial state using that type
const initialState: CheckAppToRefreshState = {
    value: '',
};

type CheckAppToRefreshPayload = {
    appId: string;
};

export const checkAppToRefreshSlice = createSlice({
    name: 'checkAppToRefresh',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        checkAppToRefresh: (
            state,
            action: PayloadAction<CheckAppToRefreshPayload>,
        ) => {
            if (action.payload !== undefined)
                state.value = action.payload.appId;
        },
    },
});

export const { checkAppToRefresh } = checkAppToRefreshSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCheckAppToRefresh = (state: RootState) =>
    state.checkAppToRefresh.value;

export default checkAppToRefreshSlice.reducer;
