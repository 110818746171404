import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { AumeeAccounts } from '@dyrloz/aumee-client';

// Define a type for the slice state
interface UserPermissionsOnAppState {
    value: string[];
}

// Define the initial state using that type
const initialState: UserPermissionsOnAppState = {
    value: null,
};

export const userPermissionsOnAppSlice = createSlice({
    name: 'userPermissionsOnApp',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setUserPermissionsOnApp: (state, action: PayloadAction<string[]>) => {
            if (AumeeAccounts.userPermissions.includes('apps.write'))
                action.payload.push('manager.apps.write');
            state.value = action.payload;
        },
    },
});

export const { setUserPermissionsOnApp } = userPermissionsOnAppSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectUserPermissionsOnApp = (state: RootState) =>
    state.userPermissionsOnApp.value;

export default userPermissionsOnAppSlice.reducer;
