import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

// Define a type for the slice state
interface AppLoadingState {
    value: string;
}

// Define the initial state using that type
const initialState: AppLoadingState = {
    value: '',
};

type AppLoadingPayload = {
    appLoading: string;
};

export const appLoadingSlice = createSlice({
    name: 'isAppLoading',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setAppLoading: (state, action: PayloadAction<AppLoadingPayload>) => {
            if (action.payload !== undefined)
                state.value = action.payload.appLoading;
        },
    },
});

export const { setAppLoading } = appLoadingSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAppLoading = (state: RootState) => state.isAppLoading.value;

export default appLoadingSlice.reducer;
