import './Accesses.css';
import { AumeeApp } from '../AumeeApp';
import { connect } from 'react-redux';
import { setAppLoading } from '../../store/slices/app-loading';
import { setAppRefreshData } from '../../store/slices/app-refresh-data';
import assistantSteps from './accesses.assistant';
import { AumeeClient, AumeeTranslator, AumeeURL } from '@dyrloz/aumee-client';
import {
    AbsoluteIconButton,
    Button,
    ButtonGroup,
    Container,
    Divider,
    IconFactory,
    InputText,
    Select,
    Spacer,
    Table,
    Tooltip,
} from '@dyrloz/aumee-design';

type AccessesState = {
    type: 'user' | 'app';
    accountName: string;
    accountId: string;
    page: number;
    pageLoaded: number[];
    range: number;
    orderBy: string;
    sort: string;

    totalValidUser: number;
    totalUser: number;
    totalValidApp: number;
    totalApp: number;
    accounts: Account[];
    appsWithPermission: Account[];

    appRoles: Role[];
    appRoleToAdd: string;

    accountSelected: Account;
    accountSelectedAccesses: Access[];

    isAccountsLoading: boolean;
    isAccessesLoading: boolean;
};

type Account = {
    id: string;
    account_name: string;
    isAppAccessValid: string;
};

type Role = {
    id: string;
    name: string;
    price: number;
};

type Access = {
    role_id: string;
    role_name: string;
    expiration_date: string;
    nb_days_next_period: number;
    price_next_period: number;
};

class Accesses extends AumeeApp<AccessesState> {
    private accountsHeaders = {
        account_name: AumeeTranslator.translate('accesses.account_name'),
        isAppAccessValid: AumeeTranslator.translate('accesses.app_access'),
    };
    private rolesHeaders = {
        role_name: AumeeTranslator.translate('accesses.role_name'),
        expiration_date: AumeeTranslator.translate('accesses.expiration_date'),
    };
    private columnWidth = {
        account_name: {
            percent: 50,
            minWidth: 150,
        },
        isAppAccessValid: {
            percent: 50,
            minWidth: 50,
        },
        role_name: {
            percent: 50,
            minWidth: 100,
        },
        expiration_date: {
            percent: 50,
            minWidth: 150,
        },
    };
    private actualDate: Date;
    private nbDaysUntilSeventh: number = 0;

    constructor(props: any) {
        super('Accesses', props, assistantSteps);

        this.actualDate = new Date();
        let dateToIncrement = new Date();

        while (dateToIncrement.getUTCDate() != 7) {
            this.nbDaysUntilSeventh++;
            dateToIncrement.setUTCDate(dateToIncrement.getUTCDate() + 1);
        }

        const queryParameters = AumeeURL.extractURLParameters();

        this.state = {
            type: (queryParameters.type as 'user' | 'app') || 'user',
            accountName: (queryParameters.account_name as string) || '',
            accountId: (queryParameters.account_id as string) || '',
            page: 1,
            pageLoaded: [1],
            range: parseInt(queryParameters.range as string) || 10,
            orderBy: 'account_name',
            sort: (queryParameters.sort as string) || 'asc',
            totalValidUser: 0,
            totalUser: 0,
            totalValidApp: 0,
            totalApp: 0,
            appRoles: [],
            appRoleToAdd: null,
            accounts: [],
            appsWithPermission: [],
            accountSelected: null,
            accountSelectedAccesses: [],
            isAccountsLoading: false,
            isAccessesLoading: false,
        };

        AumeeURL.addQueryParameterToURL('type=' + this.state.type);
        if (this.state.accountName !== '')
            AumeeURL.addQueryParameterToURL(
                'account_name=' + this.state.accountName,
            );
        AumeeURL.addQueryParameterToURL('range=' + this.state.range);
        AumeeURL.addQueryParameterToURL('page=' + this.state.page);
        AumeeURL.addQueryParameterToURL('order_by=' + this.state.orderBy);
        AumeeURL.addQueryParameterToURL('sort=' + this.state.sort);

        this.onTypeChange = this.onTypeChange.bind(this);
        this.onAccountNameChange = this.onAccountNameChange.bind(this);
        this.onAccountIdChange = this.onAccountIdChange.bind(this);
        this.onRefreshClicked = this.onRefreshClicked.bind(this);

        this.onSortChange = this.onSortChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onRangeChange = this.onRangeChange.bind(this);
        this.onRowClick = this.onRowClick.bind(this);

        this.onAddAccess = this.onAddAccess.bind(this);

        this.props.dispatch(
            setAppLoading({
                appLoading: this.appName,
            }),
        );
        this.props.dispatch(
            setAppRefreshData({
                appRefreshData: this.appName,
                appId: this.props.appId,
            }),
        );
    }

    protected async refreshAppData() {
        this.setState({
            appRoles: [],
            appRoleToAdd: null,
            accountSelected: null,
            accountSelectedAccesses: [],
        });

        this.refreshAccountsAccesses();
    }

    private async refreshAccountsAccesses() {
        this.setState({
            page: 1,
            pageLoaded: [1],
            isAccountsLoading: true,
        });
        AumeeURL.addQueryParameterToURL('page=1');
        const requestParameters = AumeeURL.getURLParametersQuery();

        // GET TOTAL OF ACCOUNTS WITH PARAMETERS - PAGE & RANGE
        const count = await AumeeClient.get(
            '/accounts/count' +
                requestParameters.replace(
                    /&range=[0-9]+&page=[0-9]+&order_by=[a-z_.]+&sort=(asc|desc)/g,
                    '',
                ),
        )
            .then((res) => res.json())
            .then((res) => res.data);

        // GET ACCOUNTS WITH FULL PARAMETERS
        let accounts: Account[] = await AumeeClient.get(
            '/accounts' + requestParameters,
        )
            .then((res) => res.json())
            .then((res) => res.data);

        this.props.dispatch(setAppLoading({ appLoading: '' }));
        if (this.state.type === 'user') {
            this.setState({
                accounts,
                totalUser: count.total,
                accountSelected: null,
                appsWithPermission: [],
                totalValidUser: count.total_valid_access,
                isAccountsLoading: false,
                isAccessesLoading: false,
            });
        } else {
            const appsWithPermission = await AumeeClient.get(
                '/accounts/me/apps/manager.read',
                false,
                null,
            )
                .then((res) => res.json())
                .then((res) => res.data)
                .catch(() => null);

            this.setState({
                accounts,
                accountSelected: null,
                appsWithPermission: appsWithPermission.map((app: any) => {
                    return {
                        id: app.id,
                        account_name: app.account_name + '/|/' + app.icon,
                    };
                }),
                totalApp: count.total,
                totalValidApp: count.total_valid_access,
                isAccountsLoading: false,
                isAccessesLoading: false,
            });
        }
    }

    private async refreshAccesses(accountId?: string) {
        this.actualDate = new Date();
        const accesses = await AumeeClient.get(
            '/accounts/' +
                (accountId || this.state.accountSelected.id) +
                '/accesses/apps/' +
                this.props.appId,
        )
            .then((res) => res.json())
            .then((res) => res.data);

        this.setState({
            isAccessesLoading: false,
            appRoleToAdd: null,
            accountSelectedAccesses: this.formatAccesses(accesses).sort(
                (a: Access, b: Access) =>
                    a.role_name > b.role_name
                        ? 1
                        : a.role_name < b.role_name
                        ? -1
                        : 0,
            ),
        });
    }

    private formatAccesses(accesses: Access[]) {
        return accesses.map((access) => {
            let nbDays = 0;
            let expirationDate = new Date(access.expiration_date);
            let dateToIncrement = new Date();
            if (expirationDate >= this.actualDate) {
                while (
                    dateToIncrement.getUTCDate() !==
                        this.actualDate.getUTCDate() ||
                    dateToIncrement.getUTCMonth() !==
                        this.actualDate.getUTCMonth() ||
                    dateToIncrement.getUTCFullYear() !==
                        this.actualDate.getUTCFullYear()
                ) {
                    nbDays++;
                    dateToIncrement.setUTCDate(
                        dateToIncrement.getUTCDate() + 1,
                    );
                }
            }

            const defaultPrice =
                this.state.appRoles.find((role) => role.id === access.role_id)
                    ?.price || 0;

            return {
                ...access,
                nb_days_next_period: nbDays,
                price_next_period: defaultPrice,
            };
        });
    }

    private onTypeChange(type: string) {
        const realType = type === 'users' ? 'user' : 'app';
        this.setState({ type: realType });
        AumeeURL.addQueryParameterToURL('type=' + realType);
        this.refreshAccountsAccesses();
    }

    private onAccountNameChange(accountName: string) {
        this.setState({ accountName });
        if (accountName.trim() !== '')
            AumeeURL.addQueryParameterToURL('account_name=' + accountName);
        else AumeeURL.removeQueryParameterFromURL('account_name');
    }

    private onAccountIdChange(accountId: string) {
        this.setState({ accountId });
        if (accountId.trim().length === 24)
            AumeeURL.addQueryParameterToURL('account_id=' + accountId);
        else AumeeURL.removeQueryParameterFromURL('account_id');
    }

    private onRefreshClicked() {
        this.refreshAccountsAccesses();
    }

    private onSortChange(attribute: string, direction: number) {
        const realSort = direction > 0 ? 'asc' : 'desc';
        this.setState({
            orderBy: attribute,
            sort: realSort,
        });
        AumeeURL.addQueryParameterToURL('order_by=' + attribute);
        AumeeURL.addQueryParameterToURL('sort=' + realSort);
        this.refreshAccountsAccesses();
    }

    private async onPageChange(newPage: number) {
        AumeeURL.addQueryParameterToURL('page=' + newPage);
        if (!this.state.pageLoaded.includes(newPage)) {
            this.setState({
                isAccountsLoading: true,
            });
            const requestParameters = AumeeURL.getURLParametersQuery();

            const accounts = await AumeeClient.get(
                '/accounts' + requestParameters,
            )
                .then((res) => res.json())
                .then((res) => res.data);

            this.state.pageLoaded.push(newPage);

            this.setState({
                accounts: this.state.accounts.concat(accounts),
                isAccountsLoading: false,
                page: newPage,
            });
        } else {
            this.setState({ page: newPage });
        }
    }

    private onRangeChange(range: number) {
        this.setState({ range });
        AumeeURL.addQueryParameterToURL('range=' + range);
    }

    private async onRowClick(row: number) {
        const account =
            this.state.type === 'user'
                ? this.state.accounts[row - 1]
                : this.state.accounts.concat(
                      this.state.appsWithPermission.filter(
                          (app) =>
                              app.account_name.match(
                                  '^' + this.state.accountName,
                              ) &&
                              !this.state.accounts.find(
                                  (account) => account.id === app.id,
                              ),
                      ),
                  )[row - 1];

        this.setState({
            isAccessesLoading: true,
            accountSelected: account,
        });

        if (
            this.state.appRoles.length === 0 &&
            this.props.userPermissionsOnApp.includes('access.write') &&
            this.props.userPermissionsOnApp.includes('role.read')
        ) {
            const roles = await AumeeClient.get(
                '/accounts/apps/' + this.props.appId + '/roles',
            )
                .then((res) => res.json())
                .then((res) => res.data);

            this.setState({
                appRoles: roles,
            });
        }

        this.refreshAccesses(account.id);
    }

    private async onAddAccess() {
        const roleToAdd = this.state.appRoleToAdd || 'user';
        const accountId =
            this.state.accountSelected?.id || this.state.accountId;
        this.setState({
            isAccessesLoading: true,
        });
        await AumeeClient.post(
            '/accounts/' + accountId + '/accesses',
            {
                role:
                    this.state.appRoles.find((role) => role.id === roleToAdd)
                        ?.name || roleToAdd,
                app_id: this.props.appId,
            },
            true,
            { key: 'accesses.add.', withStatus: true },
        );

        if (this.state.accountSelected) this.refreshAccesses();
        else this.refreshAccountsAccesses();
    }

    private async onModifyExpirationDate(role: string, action: string) {
        this.setState({
            isAccessesLoading: true,
        });
        await AumeeClient.patch(
            '/accounts/' + this.state.accountSelected.id + '/accesses',
            {
                role,
                action,
                app_id: this.props.appId,
            },
        );

        this.refreshAccesses();
    }

    render() {
        const {
            type,
            accountName,
            accountId,
            page,
            range,
            orderBy,
            sort,
            totalValidUser,
            totalUser,
            totalValidApp,
            totalApp,
            appRoles,
            appRoleToAdd,
            accounts,
            appsWithPermission,
            accountSelected,
            accountSelectedAccesses,
            isAccountsLoading,
            isAccessesLoading,
        } = this.state;

        const hasAccessWritePermission =
            this.props.userPermissionsOnApp.includes('access.write') &&
            this.props.userPermissionsOnApp.includes('role.read');

        const appsWithPermissionFiltered = appsWithPermission.filter(
            (app) =>
                app.account_name.match('^' + accountName) &&
                !accounts.find((account) => account.id === app.id),
        );

        let bottomActionComponent = null;
        if (!isAccessesLoading && accountSelected && hasAccessWritePermission) {
            bottomActionComponent = (
                <div className="add-access">
                    <Select
                        fatherId={this.id}
                        usage="add-access"
                        values={appRoles
                            .filter(
                                (role) =>
                                    !accountSelectedAccesses.find(
                                        (access) => access.role_id === role.id,
                                    ),
                            )
                            .map((role) => {
                                return {
                                    value: role.id,
                                    text: role.name,
                                };
                            })}
                        placeholder={AumeeTranslator.translate(
                            'accesses.select_role',
                        )}
                        isInfoTextDisplayed={false}
                        emptyMessage={AumeeTranslator.translate(
                            'accesses.roles_not_found',
                        )}
                        optionsPlacement="top"
                        size="medium"
                        withInput={true}
                        onValueChange={(value: string) =>
                            this.setState({ appRoleToAdd: value })
                        }
                    />
                    <Spacer size="tiny" direction="vertical" />
                    <Button
                        fatherId={this.id}
                        usage="add-access"
                        icon="add"
                        iconSize="24"
                        onClick={this.onAddAccess}
                        disabled={!appRoleToAdd}
                    />
                </div>
            );
        }

        return (
            <div id={this.id} className="accesses page">
                {this.getAssistant()}
                <Container
                    fatherId={this.id}
                    usage={`filters${hasAccessWritePermission ? '-add' : ''}`}
                >
                    {!accountSelected && (
                        <div className="filters">
                            <div className="type">
                                <div
                                    id="accesses_total_users"
                                    className={`total ${
                                        !isAccountsLoading && type === 'user'
                                            ? ''
                                            : 'invisible'
                                    }`}
                                >
                                    <span>{totalValidUser}</span>
                                    <Divider
                                        color="tertiary"
                                        margin="tiny"
                                        direction="horizontal"
                                    />
                                    <span>{totalUser}</span>
                                </div>
                                <Spacer size="small" direction="vertical" />
                                <ButtonGroup
                                    fatherId={this.id}
                                    usage="type"
                                    value={type === 'user' ? 'users' : 'aumee'}
                                    values={['users', 'aumee']}
                                    valueType="icon"
                                    onClick={this.onTypeChange}
                                />
                                <Spacer size="small" direction="vertical" />
                                <div
                                    className={`total ${
                                        !isAccountsLoading && type === 'app'
                                            ? ''
                                            : 'invisible'
                                    }`}
                                >
                                    <span>{totalValidApp}</span>
                                    <Divider
                                        color="tertiary"
                                        margin="tiny"
                                        direction="horizontal"
                                    />
                                    <span>{totalApp}</span>
                                </div>
                            </div>
                            <Spacer size="tiny" />
                            <div className="search">
                                <InputText
                                    fatherId={this.id}
                                    usage="account-name"
                                    type="search"
                                    size="medium"
                                    placeholder={AumeeTranslator.translate(
                                        'accesses.account_name',
                                    )}
                                    value={accountName}
                                    onValueChange={this.onAccountNameChange}
                                />
                                <Spacer size="tiny" direction="vertical" />
                                <Button
                                    fatherId={this.id}
                                    usage="refresh"
                                    icon="refresh"
                                    iconSize="24"
                                    onClick={this.onRefreshClicked}
                                />
                            </div>
                            {hasAccessWritePermission && (
                                <Spacer size={'tiny'} />
                            )}
                            {hasAccessWritePermission && (
                                <div className="add-account">
                                    <InputText
                                        fatherId={this.id}
                                        usage="account-id"
                                        type="search"
                                        size="medium"
                                        placeholder={AumeeTranslator.translate(
                                            'accesses.add_account_id',
                                        )}
                                        validationMinLength={1}
                                        minLength={24}
                                        errorMinLength={AumeeTranslator.translate(
                                            'accesses.add.account_id.min',
                                        )}
                                        maxLength={24}
                                        errorMaxLength={AumeeTranslator.translate(
                                            'accesses.add.account_id.max',
                                        )}
                                        value={accountId}
                                        onValueChange={this.onAccountIdChange}
                                    />
                                    <Spacer size="tiny" direction="vertical" />
                                    <Tooltip
                                        label={AumeeTranslator.translate(
                                            'accesses.add.account_id.description',
                                        )}
                                        isVisible={
                                            accountId.trim().length === 24
                                        }
                                        position="vertical"
                                        forcedWidth={150}
                                    >
                                        <Button
                                            fatherId={this.id}
                                            usage="add-account"
                                            icon="add"
                                            iconSize="24"
                                            onClick={this.onAddAccess}
                                            disabled={
                                                accountId.trim().length !== 24
                                            }
                                        />
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    )}
                    {accountSelected && (
                        <div className="account-selected">
                            <span>{accountSelected.id}</span>
                            <Spacer size="tiny" />
                            <div className="account-name">
                                <img
                                    src={
                                        accountSelected.account_name.split(
                                            '/|/',
                                        )[1]
                                    }
                                />
                                <span>
                                    {
                                        accountSelected.account_name.split(
                                            '/|/',
                                        )[0]
                                    }
                                </span>
                            </div>
                            <Spacer size="small" />
                            <div className="app-access">
                                {IconFactory.createIcon({
                                    icon:
                                        accountSelected.isAppAccessValid ===
                                        'true'
                                            ? 'validate'
                                            : 'delete',
                                    size: '24',
                                    color:
                                        accountSelected.isAppAccessValid ===
                                        'true'
                                            ? 'primary'
                                            : 'tertiary',
                                })}
                            </div>
                        </div>
                    )}
                </Container>
                <Spacer />
                <Container fatherId={this.id} usage="accounts" padding="epic">
                    <div
                        className={`table-switch ${
                            !accountSelected ? 'selected' : 'not-selected'
                        }`}
                        style={{
                            transform: 'translateX(' + -865 + 'px)',
                        }}
                    >
                        <Table
                            fatherId={this.id}
                            usage="accounts"
                            headers={this.accountsHeaders}
                            values={accounts.concat(appsWithPermissionFiltered)}
                            widths={this.columnWidth}
                            useDisplayComponent={{
                                account_name: (value: string) => {
                                    const icon = value.split('/|/')[1];
                                    const name = value.split('/|/')[0];

                                    return (
                                        <div className="account-name">
                                            <img src={icon} />
                                            <span>{name}</span>
                                        </div>
                                    );
                                },
                                isAppAccessValid: (value: string) => {
                                    return (
                                        <div className="app-access">
                                            {IconFactory.createIcon({
                                                icon:
                                                    value === 'true'
                                                        ? 'validate'
                                                        : 'delete',
                                                size: '24',
                                                color:
                                                    value === 'true'
                                                        ? 'primary'
                                                        : 'tertiary',
                                            })}
                                        </div>
                                    );
                                },
                            }}
                            emptyMessage={AumeeTranslator.translate(
                                'accesses.not_found',
                            )}
                            isLoading={isAccountsLoading}
                            isValuesScrollingX={false}
                            onPageChange={this.onPageChange}
                            range={range}
                            page={page}
                            total={
                                type === 'user'
                                    ? totalUser
                                    : totalApp +
                                      appsWithPermissionFiltered.length
                            }
                            sort={orderBy}
                            sortDirection={sort === 'asc' ? 1 : -1}
                            onSortChange={this.onSortChange}
                            onRangeChange={this.onRangeChange}
                            onRowClick={this.onRowClick}
                        />
                    </div>
                    {accountSelected && (
                        <AbsoluteIconButton
                            fatherId={this.id}
                            usage="unselect-account"
                            icon="return"
                            style="underlined"
                            verticalPosition="top"
                            horizontalPosition="left"
                            marginHorizontal="small"
                            marginVertical="small"
                            onClick={() =>
                                this.setState({
                                    accountSelected: null,
                                    accountSelectedAccesses: [],
                                })
                            }
                        />
                    )}
                    <div
                        className={`table-switch ${
                            accountSelected ? 'selected' : 'not-selected'
                        }`}
                        style={{
                            transform: 'translateX(' + 865 + 'px)',
                        }}
                    >
                        <Table
                            fatherId={this.id}
                            usage="roles"
                            headers={this.rolesHeaders}
                            values={accountSelectedAccesses}
                            widths={this.columnWidth}
                            useDisplayComponent={{
                                role_name: (value: string) => {
                                    const roleSplit = value.split('/|/');
                                    const roleName = roleSplit[0];
                                    const roleIsUnderFreePeriod =
                                        roleSplit[1] === 'true';
                                    const roleIsUnderSubscribtion =
                                        roleSplit[2] === 'true';
                                    return (
                                        <div className="role-name aumee--flex-center">
                                            <span>{roleName}</span>
                                            {roleIsUnderFreePeriod &&
                                                IconFactory.createIcon({
                                                    icon: 'gift',
                                                    size: '24',
                                                    color: 'primary',
                                                })}
                                            {roleIsUnderSubscribtion &&
                                                IconFactory.createIcon({
                                                    icon: 'dollar',
                                                    size: '24',
                                                    color: 'primary',
                                                })}
                                        </div>
                                    );
                                },
                                expiration_date: (
                                    value: string,
                                    index: number,
                                ) => {
                                    const isValid =
                                        new Date(value) > this.actualDate;
                                    return (
                                        <div className="expiration-date">
                                            <Tooltip
                                                fatherId={this.id}
                                                usage={
                                                    'expiration-date_' +
                                                    (index + 1)
                                                }
                                                label={AumeeTranslator.translate(
                                                    'accesses.role.price',
                                                    accountSelectedAccesses[
                                                        index
                                                    ].nb_days_next_period.toString(),
                                                    accountSelectedAccesses[
                                                        index
                                                    ].price_next_period.toString(),
                                                )}
                                                isVisible={
                                                    hasAccessWritePermission
                                                }
                                                forcedPosition="left"
                                            >
                                                <div className="value">
                                                    <div
                                                        className={`modify-access remove ${
                                                            isValid &&
                                                            hasAccessWritePermission
                                                                ? ''
                                                                : 'invisible'
                                                        }`}
                                                    >
                                                        <Button
                                                            fatherId={this.id}
                                                            usage={
                                                                'remove-access_' +
                                                                accountSelectedAccesses[
                                                                    index
                                                                ].role_name.split(
                                                                    '/|/',
                                                                )[0]
                                                            }
                                                            icon="remove"
                                                            style="underlined-alert"
                                                            iconSize="24"
                                                            onClick={() =>
                                                                this.onModifyExpirationDate(
                                                                    accountSelectedAccesses[
                                                                        index
                                                                    ].role_name.split(
                                                                        '/|/',
                                                                    )[0],
                                                                    'decrease',
                                                                )
                                                            }
                                                            disabled={
                                                                !isValid ||
                                                                !hasAccessWritePermission
                                                            }
                                                        />
                                                    </div>

                                                    <Spacer
                                                        size="tiny"
                                                        direction="vertical"
                                                    />
                                                    {new Date(
                                                        value,
                                                    ).toLocaleString()}
                                                    <Spacer
                                                        size="tiny"
                                                        direction="vertical"
                                                    />
                                                    <div
                                                        className={`modify-access extend ${
                                                            value.indexOf(
                                                                '9999-',
                                                            ) === 0 ||
                                                            !hasAccessWritePermission
                                                                ? 'invisible'
                                                                : ''
                                                        }`}
                                                    >
                                                        <Button
                                                            fatherId={this.id}
                                                            usage={
                                                                'extend-access_' +
                                                                accountSelectedAccesses[
                                                                    index
                                                                ].role_name.split(
                                                                    '/|/',
                                                                )[0]
                                                            }
                                                            icon="add"
                                                            style="underlined-primary"
                                                            iconSize="24"
                                                            onClick={() =>
                                                                this.onModifyExpirationDate(
                                                                    accountSelectedAccesses[
                                                                        index
                                                                    ].role_name.split(
                                                                        '/|/',
                                                                    )[0],
                                                                    'increase',
                                                                )
                                                            }
                                                            disabled={
                                                                value.indexOf(
                                                                    '9999-',
                                                                ) === 0 ||
                                                                !hasAccessWritePermission
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className={`modify-access infinite ${
                                                            value.indexOf(
                                                                '9999-',
                                                            ) === 0 ||
                                                            !hasAccessWritePermission
                                                                ? 'invisible'
                                                                : ''
                                                        }`}
                                                    >
                                                        <Button
                                                            fatherId={this.id}
                                                            usage={
                                                                'infinite-access_' +
                                                                accountSelectedAccesses[
                                                                    index
                                                                ].role_name.split(
                                                                    '/|/',
                                                                )[0]
                                                            }
                                                            icon="infinite"
                                                            iconSize="16"
                                                            onClick={() =>
                                                                this.onModifyExpirationDate(
                                                                    accountSelectedAccesses[
                                                                        index
                                                                    ].role_name.split(
                                                                        '/|/',
                                                                    )[0],
                                                                    'infinite',
                                                                )
                                                            }
                                                            disabled={
                                                                value.indexOf(
                                                                    '9999-',
                                                                ) === 0 ||
                                                                !hasAccessWritePermission
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </Tooltip>
                                            <Spacer size="tiny" />
                                            <div className="validity">
                                                {IconFactory.createIcon({
                                                    icon: isValid
                                                        ? 'validate'
                                                        : 'delete',
                                                    size: '24',
                                                    color: isValid
                                                        ? 'primary'
                                                        : 'tertiary',
                                                })}
                                            </div>
                                        </div>
                                    );
                                },
                            }}
                            emptyMessage={AumeeTranslator.translate(
                                'accesses.roles_not_found',
                            )}
                            bottomActionComponent={bottomActionComponent}
                            isLoading={isAccessesLoading}
                            isValuesScrollingX={false}
                            sort={'role_name'}
                            total={accountSelectedAccesses.length}
                        />
                    </div>
                </Container>
            </div>
        );
    }
}

export default connect()(Accesses);
