import './Translator.css';
import {
    Button,
    Container,
    ALL_LOCALES,
    Locales,
    InputText,
    Tooltip,
    SelectLocale,
    Loader,
    Assistant,
    DeleteButton,
    AbsoluteIconButton,
    Spacer,
} from '@dyrloz/aumee-design';
import { AumeeClient, AumeeTranslator, AumeeURL } from '@dyrloz/aumee-client';
import { setAppLoading } from '../../store/slices/app-loading';
import { setAppRefreshData } from '../../store/slices/app-refresh-data';
import { connect } from 'react-redux';
import { AumeeApp } from '../AumeeApp';
import { EditableText } from '../../components/editable-text/EditableText';
import assistantSteps from './translator.assistant';

type TranslatorState = {
    translations: Translation[];
    defaultTranslation: Translation;
    universalTranslation: Translation;
    localeSelected: Locales;
    localeCompare: Locales;
    filteredDefaultKeys: string[];
    searchKey: string;
    isLocaleCompareDisplayed: boolean;
    isInputVisibleOnCell: { [key: string]: boolean };
    isLoadingVisibleOnCell: { [key: string]: boolean };
    isKeyAlreadyExistsOnCell: { [key: string]: boolean };
    isLoadingDisplayed: boolean;
    isAddingNewKey: boolean;
    isNewKeyLoading: boolean;
    isNewKeyAlreadyExists: boolean;
};

type Translation = {
    id: string;
    app_id: string;
    version: string;
    locale: string;
    last_update_date: Date;
    keys: { [key: string]: string };
};

class Translator extends AumeeApp<TranslatorState> {
    constructor(props: any) {
        super('Translator', props, assistantSteps);

        this.state = {
            translations: [],
            localeSelected:
                (AumeeURL.extractURLParameters()['locale'] as Locales) || 'en',
            localeCompare: 'en',
            searchKey: '',
            filteredDefaultKeys: [],
            defaultTranslation: null,
            universalTranslation: null,
            isLocaleCompareDisplayed: false,
            isInputVisibleOnCell: {},
            isLoadingVisibleOnCell: {},
            isKeyAlreadyExistsOnCell: {},
            isLoadingDisplayed: false,
            isAddingNewKey: false,
            isNewKeyLoading: false,
            isNewKeyAlreadyExists: false,
        };

        this.props.dispatch(
            setAppLoading({
                appLoading: this.appName,
            }),
        );
        this.props.dispatch(
            setAppRefreshData({
                appRefreshData: this.appName,
                appId: this.props.appId,
            }),
        );

        this.onLocaleCompareChange = this.onLocaleCompareChange.bind(this);
        this.onLocaleSelectedChange = this.onLocaleSelectedChange.bind(this);
        this.onSearchKeyChange = this.onSearchKeyChange.bind(this);
        this.onDeleteLocale = this.onDeleteLocale.bind(this);
        this.onCompareLocale = this.onCompareLocale.bind(this);

        this.onAddLocale = this.onAddLocale.bind(this);
        this.onDisplayAddDefaultKey = this.onDisplayAddDefaultKey.bind(this);
        this.onAddDefaultKey = this.onAddDefaultKey.bind(this);
        this.onImportDefaultKeys = this.onImportDefaultKeys.bind(this);
        this.onKeyChange = this.onKeyChange.bind(this);
        this.onDefaultKeyChange = this.onDefaultKeyChange.bind(this);
        this.onDefaultKeyDeleted = this.onDefaultKeyDeleted.bind(this);
        this.onLocaleKeyChange = this.onLocaleKeyChange.bind(this);
        this.onCellClick = this.onCellClick.bind(this);
        this.onEscapePressOnTextArea = this.onEscapePressOnTextArea.bind(this);
    }

    protected refreshAppData(appId: string = this.props.appId): void {
        AumeeClient.get('/translations/' + appId)
            .then((res) => res.json())
            .then((res) => {
                const defaultTranslation = res.data.find(
                    (translation: Translation) =>
                        translation.locale === 'default',
                );
                if (!defaultTranslation) {
                    this.setState({
                        isLoadingDisplayed: false,
                        translations: res.data,
                    });
                    this.props.dispatch(setAppLoading({ appLoading: '' }));
                    return;
                }

                let isInputVisibleOnCell: { [key: string]: boolean } = {};
                let isLoadingVisibleOnCell: { [key: string]: boolean } = {};
                let isKeyAlreadyExistsOnCell: { [key: string]: boolean } = {};
                Object.keys(defaultTranslation.keys).forEach((key) => {
                    isInputVisibleOnCell['default.' + key] = false;
                    isInputVisibleOnCell[key] = false;
                    isLoadingVisibleOnCell['default.' + key] = false;
                    isLoadingVisibleOnCell[key] = false;
                    isKeyAlreadyExistsOnCell[key] = false;
                });

                this.setState({
                    translations: res.data,
                    filteredDefaultKeys: this.filterDefaultTranslationKeys(
                        this.state.searchKey,
                        this.state.localeSelected,
                        defaultTranslation,
                        res.data,
                    ),
                    defaultTranslation,
                    universalTranslation: res.data.find(
                        (translation: Translation) =>
                            translation.locale === 'en',
                    ),
                    isInputVisibleOnCell,
                    isLoadingVisibleOnCell,
                    isKeyAlreadyExistsOnCell,
                    isLoadingDisplayed: false,
                    isAddingNewKey: false,
                    isNewKeyLoading: false,
                    isNewKeyAlreadyExists: false,
                });
                this.props.dispatch(setAppLoading({ appLoading: '' }));
            })
            .catch((error) => {
                if (error.status === 403)
                    AumeeURL.navigate('Translator', '/', false, ['app_id']);
            });
    }

    private onLocaleCompareChange(locale: Locales) {
        this.setState({
            localeCompare: locale,
        });
    }

    private onLocaleSelectedChange(locale: Locales) {
        if (!this.state.defaultTranslation) {
            this.setState({
                localeSelected: locale,
            });
        } else {
            let isInputVisibleOnCell: { [key: string]: boolean } = {};
            let isLoadingVisibleOnCell: { [key: string]: boolean } = {};
            let isKeyAlreadyExistsOnCell: { [key: string]: boolean } = {};
            Object.keys(this.state.defaultTranslation.keys).forEach((key) => {
                isInputVisibleOnCell['default.' + key] = false;
                isInputVisibleOnCell[key] = false;
                isLoadingVisibleOnCell['default.' + key] = false;
                isLoadingVisibleOnCell[key] = false;
                isKeyAlreadyExistsOnCell[key] = false;
            });
            const newState: any = {
                localeSelected: locale,
                isInputVisibleOnCell,
                isLoadingVisibleOnCell,
                isKeyAlreadyExistsOnCell,
                isLoadingDisplayed: false,
                isAddingNewKey: false,
                isNewKeyLoading: false,
                isNewKeyAlreadyExists: false,
            };
            if (locale === this.state.localeCompare) {
                newState.localeCompare = this.state.localeSelected;
            }
            this.setState(newState);
        }
        AumeeURL.addQueryParameterToURL('locale=' + locale);
        this.onSearchKeyChange(this.state.searchKey, locale);
    }

    private onAddLocale() {
        this.setState({
            isLoadingDisplayed: true,
        });
        AumeeClient.post(
            '/translations',
            {
                app_id: this.props.appId,
                locale: this.state.localeSelected,
            },
            false,
            null,
        )
            .then(() => {
                if (this.state.translations.length === 0) {
                    AumeeClient.post('/translations', {
                        app_id: this.props.appId,
                        locale: 'default',
                    }).then(() => {
                        this.refreshAppData();
                    });
                } else {
                    this.refreshAppData();
                }
            })
            .catch((error) => {
                if (error.status === 409) {
                    this.refreshAppData();
                }
            });
    }

    private filterDefaultTranslationKeys(
        searchKey: string,
        locale: string,
        defaultTranslation = this.state.defaultTranslation,
        translations = this.state.translations,
    ) {
        let filteredDefaultKeys;

        if (searchKey.replace(/[ ]{2,}/g, ' ') === ' ') {
            const selectedTranslation = translations.find(
                (translation) => translation.locale === locale,
            );
            if (!selectedTranslation) return [];
            filteredDefaultKeys = Object.keys(defaultTranslation.keys)
                .filter(
                    (key) =>
                        !selectedTranslation.keys[key] ||
                        selectedTranslation.keys[key] === '' ||
                        selectedTranslation.keys[key] === key,
                )
                .sort((a, b) => (a > b ? 1 : -1));
        } else {
            filteredDefaultKeys = Object.keys(defaultTranslation.keys)
                .filter((key) => {
                    try {
                        return key.match(
                            new RegExp(`${searchKey.toLocaleLowerCase()}`),
                        );
                    } catch {
                        return key.indexOf(searchKey.toLocaleLowerCase()) > -1;
                    }
                })
                .sort((a, b) => (a > b ? 1 : -1));
        }

        return filteredDefaultKeys;
    }

    private onSearchKeyChange(
        searchKey: string,
        locale = this.state.localeSelected,
    ) {
        this.setState({
            searchKey,
            filteredDefaultKeys: this.filterDefaultTranslationKeys(
                searchKey,
                locale,
            ),
        });
    }

    private onCompareLocale() {
        this.setState({
            isLocaleCompareDisplayed: !this.state.isLocaleCompareDisplayed,
        });
    }

    private onDeleteLocale() {
        this.setState({
            isLoadingDisplayed: true,
        });

        AumeeClient.delete(
            '/translations/' +
                this.props.appId +
                '/locales/' +
                this.state.localeSelected,
        ).then(() => {
            this.refreshAppData();
        });
    }

    private onCellClick(key: string) {
        const cellModified: { [key: string]: boolean } = {};
        cellModified[key] = true;
        this.setState({
            isInputVisibleOnCell: {
                ...this.state.isInputVisibleOnCell,
                ...cellModified,
            },
        });
    }

    private onEscapePressOnTextArea(key: string) {
        const cellModified: { [key: string]: boolean } = {};
        cellModified[key] = false;
        this.setState({
            isInputVisibleOnCell: {
                ...this.state.isInputVisibleOnCell,
                ...cellModified,
            },
        });
    }

    private onDisplayAddDefaultKey() {
        this.setState({
            isAddingNewKey: !this.state.isAddingNewKey,
        });
    }

    private onAddDefaultKey(key: string, value: string, newKey: string) {
        this.setState({
            isNewKeyLoading: true,
        });

        AumeeClient.post(
            '/translations/' + this.props.appId + '/keys',
            {
                key: newKey,
                value: value,
            },
            false,
            null,
        )
            .then(() => {
                this.setState({
                    isLoadingDisplayed: true,
                });
                this.refreshAppData();
            })
            .catch((error) => {
                if (error.status === 409) {
                    this.setState({
                        isNewKeyAlreadyExists: true,
                        isNewKeyLoading: false,
                    });
                }
            });
    }

    private onImportDefaultKeys() {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.json';
        const that = this;
        input.onchange = () => {
            const reader = new FileReader();

            reader.onload = async function (event) {
                const translations: Translation = JSON.parse(
                    event.target.result as string,
                );
                if (typeof translations.keys === 'object') {
                    const defaultTranslationKeys = Object.keys(
                        that.state.defaultTranslation.keys,
                    );
                    const keysToAdd = Object.keys(translations.keys).filter(
                        (k) => !defaultTranslationKeys.includes(k),
                    );

                    const translationsToAdd: { key: string; value: string }[] =
                        [];

                    for (const key of keysToAdd) {
                        translationsToAdd.push({
                            key,
                            value: translations.keys[key],
                        });
                    }

                    that.setState({
                        isNewKeyLoading: true,
                    });

                    await AumeeClient.post(
                        '/translations/' + that.props.appId + '/keys/import',
                        translationsToAdd,
                    ).then(() => {
                        that.setState({
                            isLoadingDisplayed: true,
                        });
                        that.refreshAppData();
                    });
                } else {
                    AumeeClient.onServerError(
                        new Error('warning.wrong_import_file'),
                    );
                }
            };

            reader.readAsText(input.files[0]);
        };
        input.click();
    }

    private onKeyChange(key: string) {
        this.setState({
            isKeyAlreadyExistsOnCell: {
                ...this.state.isKeyAlreadyExistsOnCell,
                [key]: false,
            },
        });
    }

    private onDefaultKeyChange(key: string, value: string, newKey: string) {
        const realKey = key.replace('default.', '');
        if (
            (newKey && realKey !== newKey) ||
            value !== this.state.defaultTranslation.keys[realKey]
        ) {
            this.displayLoading(key, true);
            AumeeClient.patch(
                '/translations/' + this.props.appId + '/keys/' + realKey,
                {
                    key: newKey || key,
                    value: value,
                },
                false,
                null,
            )
                .then(() => {
                    this.setState({
                        isLoadingDisplayed: true,
                    });
                    this.refreshAppData();
                })
                .catch((error) => {
                    if (error.status === 409) {
                        this.setState({
                            isLoadingVisibleOnCell: {
                                ...this.state.isLoadingVisibleOnCell,
                                [key]: false,
                            },
                            isKeyAlreadyExistsOnCell: {
                                ...this.state.isKeyAlreadyExistsOnCell,
                                [key]: true,
                            },
                        });
                    }
                });
        } else {
            this.onEscapePressOnTextArea(key);
        }
    }

    private onDefaultKeyDeleted(key: string) {
        const realKey = key.replace('default.', '');
        this.displayLoading(key, true);
        AumeeClient.delete(
            '/translations/' + this.props.appId + '/keys/' + realKey,
            true,
        ).then(() => {
            this.setState({
                isLoadingDisplayed: true,
            });
            this.refreshAppData();
        });
    }

    private onLocaleKeyChange(key: string, value: string) {
        const translation = this.state.translations.find(
            (translation) => translation.locale === this.state.localeSelected,
        );
        if (value === '' || translation.keys[key] === value) {
            this.onEscapePressOnTextArea(key);
            return;
        }

        this.displayLoading(key, true);

        const body: { [key: string]: string } = {};
        body[key] = value;

        AumeeClient.patch(
            '/translations/' +
                this.props.appId +
                '/locales/' +
                this.state.localeSelected +
                '/keys',
            body,
        ).then(() => {
            this.refreshAppData();
        });
    }

    private displayLoading(key: string, isDisplayed: boolean) {
        const cellModified: { [key: string]: boolean } = {};
        cellModified[key] = isDisplayed;
        this.setState({
            isLoadingVisibleOnCell: {
                ...this.state.isLoadingVisibleOnCell,
                ...cellModified,
            },
        });
    }

    render() {
        const { userPermissionsOnApp } = this.props;

        const {
            translations,
            localeSelected,
            localeCompare,
            searchKey,
            filteredDefaultKeys,
            defaultTranslation,
            universalTranslation,
            isLocaleCompareDisplayed,
            isInputVisibleOnCell,
            isLoadingVisibleOnCell,
            isKeyAlreadyExistsOnCell,
            isLoadingDisplayed,
            isAddingNewKey,
            isNewKeyLoading,
            isNewKeyAlreadyExists,
        } = this.state;

        const compareTranslation = translations.find(
            (translation) => translation.locale === localeCompare,
        );

        const selectedTranslation = translations.find(
            (translation) => translation.locale === localeSelected,
        );

        const hasWriteTranslationPermission =
            userPermissionsOnApp.includes('translation.write');
        const hasWriteDefaultKeyPermission =
            userPermissionsOnApp.includes('default_key.write');
        const hasWriteLocaleKeyPermission =
            userPermissionsOnApp.includes('locale_key.write');

        return (
            <div id={this.id} className="translator page">
                {this.getAssistant()}
                <Container
                    fatherId={this.id}
                    color="secondary"
                    colorVariant="light"
                    padding="tiny"
                >
                    <div>
                        <div className="value-side">
                            <div className="head">
                                <div className="cell default">
                                    <InputText
                                        fatherId={this.id}
                                        usage="search"
                                        type="search"
                                        value={searchKey}
                                        placeholder={AumeeTranslator.translate(
                                            'common.search',
                                        )}
                                        onValueChange={this.onSearchKeyChange}
                                        size="fill"
                                    />
                                </div>
                                <div
                                    className={`cell flag locale compare ${
                                        isLocaleCompareDisplayed
                                            ? 'open'
                                            : 'close'
                                    }`}
                                >
                                    <SelectLocale
                                        fatherId={this.id}
                                        usage="locale-compare"
                                        locales={
                                            translations.length > 0
                                                ? ALL_LOCALES.map((l) => l)
                                                      .sort((a) =>
                                                          translations
                                                              .map(
                                                                  (t) =>
                                                                      t.locale,
                                                              )
                                                              .includes(a)
                                                              ? -1
                                                              : 1,
                                                      )
                                                      .filter((l) =>
                                                          translations
                                                              .map(
                                                                  (t) =>
                                                                      t.locale,
                                                              )
                                                              .includes(l),
                                                      )
                                                : ALL_LOCALES
                                        }
                                        defaultValue={localeCompare}
                                        onValueChange={
                                            this.onLocaleCompareChange
                                        }
                                    />
                                    <AbsoluteIconButton
                                        fatherId={this.id}
                                        usage="compare-locale"
                                        icon="chevron"
                                        rotation={
                                            isLocaleCompareDisplayed ? 90 : 270
                                        }
                                        verticalPosition="top"
                                        horizontalPosition="right"
                                        marginHorizontal="small"
                                        marginVertical="medium"
                                        onClick={this.onCompareLocale}
                                    />
                                </div>
                                <div className="cell flag locale">
                                    <SelectLocale
                                        fatherId={this.id}
                                        usage="locale-display"
                                        locales={ALL_LOCALES.map((l) => l).sort(
                                            (a) =>
                                                translations
                                                    .map((t) => t.locale)
                                                    .includes(a)
                                                    ? -1
                                                    : 1,
                                        )}
                                        defaultValue={localeSelected}
                                        onValueChange={
                                            this.onLocaleSelectedChange
                                        }
                                    />
                                    {hasWriteTranslationPermission && (
                                        <AbsoluteIconButton
                                            fatherId={this.id}
                                            usage="delete-locale"
                                            icon="delete"
                                            verticalPosition="top"
                                            horizontalPosition="right"
                                            marginHorizontal="small"
                                            marginVertical="medium"
                                            onClick={this.onDeleteLocale}
                                        />
                                    )}
                                </div>
                            </div>
                            {isLoadingDisplayed && (
                                <div className="centered aumee--flex-center">
                                    <Loader fatherId={this.id} size="large" />
                                </div>
                            )}
                            {!isLoadingDisplayed && !selectedTranslation && (
                                <div className="centered aumee--flex-center">
                                    {hasWriteTranslationPermission && (
                                        <Button
                                            fatherId={this.id}
                                            usage="add-locale"
                                            icon="add"
                                            iconSize="64"
                                            labelSize="epic"
                                            onClick={this.onAddLocale}
                                        />
                                    )}
                                    {!hasWriteTranslationPermission && (
                                        <span className="not-created">
                                            {AumeeTranslator.translate(
                                                'translator.not_created_yet',
                                            )}
                                        </span>
                                    )}
                                </div>
                            )}

                            {!isLoadingDisplayed &&
                                translations.length > 0 &&
                                selectedTranslation && (
                                    <div className="content">
                                        {(hasWriteDefaultKeyPermission ||
                                            filteredDefaultKeys.length ===
                                                0) && (
                                            <div className="row add">
                                                <Tooltip
                                                    label={AumeeTranslator.translate(
                                                        'translator.add_default_key',
                                                    )}
                                                    position="horizontal"
                                                >
                                                    {!isAddingNewKey &&
                                                        hasWriteDefaultKeyPermission && (
                                                            <div className="cell default aumee--flex-column">
                                                                <Button
                                                                    fatherId={
                                                                        this.id
                                                                    }
                                                                    usage="add-default-key"
                                                                    icon="add"
                                                                    iconSize="24"
                                                                    onClick={
                                                                        this
                                                                            .onDisplayAddDefaultKey
                                                                    }
                                                                />
                                                                <Spacer size="small" />
                                                                <Button
                                                                    fatherId={
                                                                        this.id
                                                                    }
                                                                    usage="import-keys"
                                                                    icon="file"
                                                                    label={AumeeTranslator.translate(
                                                                        'translator.import',
                                                                    )}
                                                                    labelSize="small"
                                                                    onClick={
                                                                        this
                                                                            .onImportDefaultKeys
                                                                    }
                                                                />
                                                            </div>
                                                        )}

                                                    {isAddingNewKey &&
                                                        hasWriteDefaultKeyPermission && (
                                                            <EditableText
                                                                fatherId={
                                                                    this.id
                                                                }
                                                                usage="new-key"
                                                                isInputVisible={
                                                                    true
                                                                }
                                                                isLoadingVisible={
                                                                    isNewKeyLoading
                                                                }
                                                                isKeyAlreadyExists={
                                                                    isNewKeyAlreadyExists
                                                                }
                                                                className="cell default"
                                                                keyDisplayed=""
                                                                value=""
                                                                placeholder={AumeeTranslator.translate(
                                                                    'translator.key_description',
                                                                )}
                                                                isKeyEditable={
                                                                    true
                                                                }
                                                                isAllowedToEdit={
                                                                    hasWriteLocaleKeyPermission
                                                                }
                                                                onValidate={
                                                                    this
                                                                        .onAddDefaultKey
                                                                }
                                                                onEscapePress={
                                                                    this
                                                                        .onDisplayAddDefaultKey
                                                                }
                                                                onTextClick={
                                                                    this
                                                                        .onCellClick
                                                                }
                                                                onKeyChange={() =>
                                                                    this.setState(
                                                                        {
                                                                            isNewKeyAlreadyExists:
                                                                                false,
                                                                        },
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                </Tooltip>
                                                <div
                                                    className={`cell locale not-found ${
                                                        hasWriteDefaultKeyPermission
                                                            ? ''
                                                            : 'no-button'
                                                    }`}
                                                >
                                                    {filteredDefaultKeys.length ===
                                                        0 &&
                                                        AumeeTranslator.translate(
                                                            'translator.no_key_found',
                                                        )}
                                                </div>
                                            </div>
                                        )}
                                        {filteredDefaultKeys.map(
                                            (key, index) => {
                                                return (
                                                    <div
                                                        id={
                                                            this.id +
                                                            '_row-' +
                                                            (index + 1)
                                                        }
                                                        className="row"
                                                    >
                                                        <Tooltip
                                                            label={
                                                                defaultTranslation
                                                                    .keys[key]
                                                            }
                                                            position="horizontal"
                                                        >
                                                            <EditableText
                                                                fatherId={
                                                                    this.id
                                                                }
                                                                usage={
                                                                    'default-' +
                                                                    key.replace(
                                                                        /[._]/g,
                                                                        '-',
                                                                    )
                                                                }
                                                                className="cell translation default"
                                                                isInputVisible={
                                                                    isInputVisibleOnCell[
                                                                        'default.' +
                                                                            key
                                                                    ]
                                                                }
                                                                isLoadingVisible={
                                                                    isLoadingVisibleOnCell[
                                                                        'default.' +
                                                                            key
                                                                    ]
                                                                }
                                                                isKeyAlreadyExists={
                                                                    isKeyAlreadyExistsOnCell[
                                                                        'default.' +
                                                                            key
                                                                    ]
                                                                }
                                                                isKeyEditable={
                                                                    true
                                                                }
                                                                keyDisplayed={
                                                                    'default.' +
                                                                    key
                                                                }
                                                                value={
                                                                    defaultTranslation
                                                                        .keys[
                                                                        key
                                                                    ]
                                                                }
                                                                placeholder={AumeeTranslator.translate(
                                                                    'translator.key_description',
                                                                )}
                                                                isAllowedToEdit={
                                                                    hasWriteDefaultKeyPermission
                                                                }
                                                                isAllowedToDelete={
                                                                    true
                                                                }
                                                                onValidate={
                                                                    this
                                                                        .onDefaultKeyChange
                                                                }
                                                                onEscapePress={
                                                                    this
                                                                        .onEscapePressOnTextArea
                                                                }
                                                                onTextClick={
                                                                    this
                                                                        .onCellClick
                                                                }
                                                                onKeyChange={
                                                                    this
                                                                        .onKeyChange
                                                                }
                                                                onDelete={
                                                                    this
                                                                        .onDefaultKeyDeleted
                                                                }
                                                            />
                                                        </Tooltip>
                                                        {compareTranslation && (
                                                            <EditableText
                                                                fatherId={
                                                                    this.id
                                                                }
                                                                usage={
                                                                    'compare-' +
                                                                    key.replace(
                                                                        /[._]/g,
                                                                        '-',
                                                                    )
                                                                }
                                                                className={`cell translation locale compare ${
                                                                    isLocaleCompareDisplayed
                                                                        ? 'open'
                                                                        : 'close'
                                                                }`}
                                                                isInputVisible={
                                                                    false
                                                                }
                                                                isLoadingVisible={
                                                                    false
                                                                }
                                                                keyDisplayed={
                                                                    key
                                                                }
                                                                value={
                                                                    compareTranslation
                                                                        .keys[
                                                                        key
                                                                    ]
                                                                }
                                                                placeholder={AumeeTranslator.translate(
                                                                    'translator.value',
                                                                )}
                                                                isHTML={
                                                                    defaultTranslation.keys[
                                                                        key
                                                                    ].indexOf(
                                                                        'HTML',
                                                                    ) !== -1
                                                                }
                                                                isAllowedToEdit={
                                                                    false
                                                                }
                                                                onValidate={() =>
                                                                    null
                                                                }
                                                                onEscapePress={() =>
                                                                    null
                                                                }
                                                                onTextClick={() =>
                                                                    null
                                                                }
                                                            />
                                                        )}
                                                        <EditableText
                                                            fatherId={this.id}
                                                            usage={key.replace(
                                                                /[._]/g,
                                                                '-',
                                                            )}
                                                            className="cell translation locale"
                                                            isInputVisible={
                                                                isInputVisibleOnCell[
                                                                    key
                                                                ]
                                                            }
                                                            isLoadingVisible={
                                                                isLoadingVisibleOnCell[
                                                                    key
                                                                ]
                                                            }
                                                            keyDisplayed={key}
                                                            value={
                                                                selectedTranslation
                                                                    .keys[key]
                                                            }
                                                            placeholder={AumeeTranslator.translate(
                                                                'translator.value',
                                                            )}
                                                            isHTML={
                                                                defaultTranslation.keys[
                                                                    key
                                                                ].indexOf(
                                                                    'HTML',
                                                                ) !== -1
                                                            }
                                                            isAllowedToEdit={
                                                                hasWriteLocaleKeyPermission
                                                            }
                                                            onValidate={
                                                                this
                                                                    .onLocaleKeyChange
                                                            }
                                                            onEscapePress={
                                                                this
                                                                    .onEscapePressOnTextArea
                                                            }
                                                            onTextClick={
                                                                this.onCellClick
                                                            }
                                                        />
                                                    </div>
                                                );
                                            },
                                        )}
                                    </div>
                                )}
                            {!isLoadingDisplayed &&
                                translations.length > 0 &&
                                selectedTranslation && (
                                    <div
                                        id={this.id + '_versions'}
                                        className="bottom"
                                    >
                                        <div className="cell default">
                                            {'v' + defaultTranslation.version}
                                        </div>
                                        {compareTranslation && (
                                            <div
                                                className={`cell locale translation compare ${
                                                    isLocaleCompareDisplayed
                                                        ? 'open'
                                                        : 'close'
                                                }`}
                                            >
                                                {'v' +
                                                    compareTranslation.version}
                                            </div>
                                        )}
                                        <div className="cell locale">
                                            {'v' + selectedTranslation.version}
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default connect()(Translator);
