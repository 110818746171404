import { AumeeComponent, Button, Spacer } from '@dyrloz/aumee-design';
import { ReactNode } from 'react';
import './Filter.css';

interface IFilterProps {
    label: string;
    value: string;

    onReset: () => void;
}

export class Filter extends AumeeComponent<IFilterProps, {}> {
    constructor(props: IFilterProps) {
        super('Filter', props);
    }

    render(): ReactNode {
        const { label, value, onReset } = this.props;
        return (
            <div id={this.id} className="filter aumee--flex-center">
                <div className="text aumee--flex-center aumee--flex-column">
                    <span className="label">{label} :</span>
                    <span className="value">{value.replace(/,/g, ', ')}</span>
                </div>
                <Spacer size="tiny" direction="vertical" />
                <Button
                    fatherId={this.id}
                    usage={'filter-' + label.replace(/[._]/g, '-')}
                    icon="cross"
                    style={'primary'}
                    onClick={onReset}
                />
            </div>
        );
    }
}
