import './NewApp.css';
import { AumeeApp } from '../AumeeApp';
import { connect } from 'react-redux';
import { setAppLoading } from '../../store/slices/app-loading';
import { setAppRefreshData } from '../../store/slices/app-refresh-data';
import {
    Button,
    Color,
    ColorName,
    Container,
    Divider,
    InputText,
    ShadeColorPicker,
    Spacer,
    ThemeName,
    ThemesColors,
} from '@dyrloz/aumee-design';
import {
    AumeeAnalytics,
    AumeeClient,
    AumeeTranslator,
    AumeeURL,
} from '@dyrloz/aumee-client';
import { setAppId } from '../../store/slices/app-id';
import { EditAppInfo } from '../../components/edit-app-info/EditAppInfo';
import assistantSteps from './new-app.assistant';

type NewAppState = {
    iconUrl: string;
    appName: string;
    colors: ThemesColors;
    pattern: { [key: string]: string };
};

class NewApp extends AumeeApp<NewAppState> {
    constructor(props: any) {
        super('NewApp', props, assistantSteps);

        const defaultColor = {
            value: '#ffffff',
            shade: 30,
        };

        this.state = {
            iconUrl: 'https://cdn-icons-png.flaticon.com/512/2972/2972479.png',
            appName: '',
            pattern: {
                iconUrlInvalid: '',
                appName: '',
            },
            colors: {
                light: {
                    primary: defaultColor,
                    secondary: defaultColor,
                    tertiary: defaultColor,
                },
                dark: {
                    primary: defaultColor,
                    secondary: defaultColor,
                    tertiary: defaultColor,
                },
            },
        };

        this.onIconUrlChange = this.onIconUrlChange.bind(this);
        this.onIconError = this.onIconError.bind(this);
        this.onAppNameChange = this.onAppNameChange.bind(this);
        this.verifyAppName = this.verifyAppName.bind(this);
        this.onColorChange = this.onColorChange.bind(this);
        this.onCreate = this.onCreate.bind(this);
    }

    protected refreshAppData(): void {
        this.createApp();
    }

    private onIconUrlChange(iconUrl: string) {
        this.setState({
            iconUrl,
            pattern: {
                ...this.state.pattern,
                iconUrlInvalid: '',
            },
        });
    }

    private onIconError() {
        this.setState({
            pattern: {
                ...this.state.pattern,
                iconUrlInvalid: '-/-',
            },
        });
    }

    private onAppNameChange(appName: string) {
        this.setState({
            appName,
            pattern: {
                ...this.state.pattern,
                appName: '',
            },
        });
    }

    private async verifyAppName() {
        if (this.state.appName.length > 3 && this.state.appName.length < 51) {
            return await AumeeClient.get(
                '/accounts/exist?account_name=' + this.state.appName,
            )
                .then((res) => res.json())
                .then((res) => {
                    if (res.data.isExist) {
                        this.setState({
                            pattern: {
                                ...this.state.pattern,
                                appName: '-/-',
                            },
                        });
                    }
                    return res.data.isExist;
                });
        }

        return false;
    }

    private onColorChange(
        theme: ThemeName,
        color: ColorName,
        colorValue: Color,
    ) {
        this.setState({
            colors: {
                ...this.state.colors,
                [theme]: {
                    ...this.state.colors[theme],
                    [color]: colorValue,
                },
            },
        });
    }

    private onCreate() {
        this.props.dispatch(
            setAppLoading({
                appLoading: this.appName,
            }),
        );
        this.props.dispatch(
            setAppRefreshData({
                appRefreshData: this.appName,
                appId: this.props.appId,
            }),
        );
    }

    private async createApp() {
        let isMissingField = false;

        const isExist = await this.verifyAppName();

        if (
            this.state.iconUrl.length < 1 ||
            this.state.iconUrl.length > 200 ||
            this.state.pattern.iconUrlInvalid !== ''
        ) {
            isMissingField = true;
        }

        if (
            this.state.appName.length < 4 ||
            this.state.appName.length > 50 ||
            isExist
        ) {
            isMissingField = true;
        }

        if (isMissingField) {
            this.props.dispatch(setAppLoading({ appLoading: '' }));
            return;
        }

        AumeeClient.post(
            '/accounts/apps',
            {
                icon: this.state.iconUrl,
                account_name: this.state.appName,
                colors: this.state.colors,
            },
            false,
            { throwError: true },
        )
            .then((res) => res.json())
            .then(async (res) => {
                const appsAccesses =
                    await AumeeAnalytics.onAumeeAnalyticsInit();

                const appIdCreated = appsAccesses.find(
                    (app: any) => app.account_name === this.state.appName,
                )?.id;

                if (appIdCreated) {
                    AumeeURL.navigate(
                        this.appName,
                        `/?app_id=${appIdCreated}&basic=${res.data.basic}`,
                        false,
                    );
                    this.props.dispatch(setAppId({ appId: appIdCreated }));
                } else {
                    AumeeClient.onServerError(new Error('new_app_id'));
                    this.props.dispatch(setAppLoading({ appLoading: '' }));
                }
            })
            .catch(() =>
                this.props.dispatch(setAppLoading({ appLoading: '' })),
            );
    }

    render() {
        const { isMobileScreen } = this.props;

        const { iconUrl, appName, pattern } = this.state;

        const orientation = isMobileScreen ? 'horizontal' : 'vertical';

        return (
            <div id={this.id} className="new-app page">
                {this.getAssistant()}
                <Container
                    fatherId={this.id}
                    color="secondary"
                    colorVariant="light"
                    padding="large"
                >
                    <div className="app-info">
                        <EditAppInfo
                            fatherId={this.id}
                            iconUrl={iconUrl}
                            name={appName}
                            orientation={orientation}
                            pattern={pattern}
                            onIconUrlChange={this.onIconUrlChange}
                            onIconError={this.onIconError}
                            onNameChange={this.onAppNameChange}
                            verifyAppName={this.verifyAppName}
                        />
                        <Spacer size="small" />
                        <div id={this.id + '_colors'} className="colors">
                            <div className="color">
                                <span>
                                    {AumeeTranslator.translate('app.primary')}
                                </span>
                                <Spacer size="small" />
                                <ShadeColorPicker
                                    fatherId={this.id}
                                    usage="primary-light"
                                    label={AumeeTranslator.translate(
                                        'app.light',
                                    )}
                                    onColorChange={(color: Color) =>
                                        this.onColorChange(
                                            'light',
                                            'primary',
                                            color,
                                        )
                                    }
                                />
                                <Spacer size="small" />
                                <ShadeColorPicker
                                    fatherId={this.id}
                                    usage="primary-dark"
                                    label={AumeeTranslator.translate(
                                        'app.dark',
                                    )}
                                    onColorChange={(color: Color) =>
                                        this.onColorChange(
                                            'dark',
                                            'primary',
                                            color,
                                        )
                                    }
                                />
                            </div>
                            <Divider
                                direction={orientation}
                                color="primary"
                                margin="medium"
                            />
                            <div className="color">
                                <span>
                                    {AumeeTranslator.translate('app.secondary')}
                                </span>
                                <Spacer size="small" />
                                <ShadeColorPicker
                                    fatherId={this.id}
                                    usage="secondary-light"
                                    label={AumeeTranslator.translate(
                                        'app.light',
                                    )}
                                    onColorChange={(color: Color) =>
                                        this.onColorChange(
                                            'light',
                                            'secondary',
                                            color,
                                        )
                                    }
                                />
                                <Spacer size="small" />
                                <ShadeColorPicker
                                    fatherId={this.id}
                                    usage="secondary-dark"
                                    label={AumeeTranslator.translate(
                                        'app.dark',
                                    )}
                                    onColorChange={(color: Color) =>
                                        this.onColorChange(
                                            'dark',
                                            'secondary',
                                            color,
                                        )
                                    }
                                />
                            </div>

                            <Divider
                                direction={orientation}
                                color="primary"
                                margin="medium"
                            />
                            <div className="color">
                                <span>
                                    {AumeeTranslator.translate('app.tertiary')}
                                </span>
                                <Spacer size="small" />
                                <ShadeColorPicker
                                    fatherId={this.id}
                                    usage="tertiary-light"
                                    label={AumeeTranslator.translate(
                                        'app.light',
                                    )}
                                    onColorChange={(color: Color) =>
                                        this.onColorChange(
                                            'light',
                                            'tertiary',
                                            color,
                                        )
                                    }
                                />
                                <Spacer size="small" />
                                <ShadeColorPicker
                                    fatherId={this.id}
                                    usage="tertiary-dark"
                                    label={AumeeTranslator.translate(
                                        'app.dark',
                                    )}
                                    onColorChange={(color: Color) =>
                                        this.onColorChange(
                                            'dark',
                                            'tertiary',
                                            color,
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </Container>
                <Spacer size="medium" />
                <Button
                    fatherId={this.id}
                    usage="create"
                    label={AumeeTranslator.translate('new_app.create')}
                    icon="validate"
                    onClick={this.onCreate}
                />
            </div>
        );
    }
}

export default connect()(NewApp);
