import { Direction } from '@dyrloz/aumee-design';

export default [
    {
        id: 'app-info_container_info',
        position: 'vertical' as Direction,
        text: 'app_info.assistant.info',
    },
    {
        id: 'app-info_container_permissions',
        position: 'vertical' as Direction,
        text: 'app_info.assistant.permissions',
    },
    {
        id: 'app-info_container_roles',
        position: 'vertical' as Direction,
        text: 'app_info.assistant.roles',
    },
    {
        id: 'app-info_container_colors',
        position: 'vertical' as Direction,
        text: 'app_info.assistant.colors',
    },
];
