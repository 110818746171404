import './EditAppInfo.css';
import {
    AumeeComponent,
    Button,
    InputText,
    Loader,
    Spacer,
    SpanHTMLParser,
    TextArea,
} from '@dyrloz/aumee-design';
import { AumeeTranslator } from '@dyrloz/aumee-client';

interface IEditAppInfoProps {
    iconUrl: string;
    name: string;
    url?: string;

    pattern: { [key: string]: string };

    orientation: 'horizontal' | 'vertical';

    onIconError: () => void;
    onIconUrlChange: (iconUrl: string) => void;
    onNameChange: (name: string) => void;
    verifyAppName: () => void;
    onUrlChange?: (url: string) => void;
}

type EditAppInfoState = {};

/**
 * EditAppInfo is a component that display a span text or html text>.
 * If clicked, then enter edit mode with a text area.
 * If loading, display a loader at the middle.
 */
export class EditAppInfo extends AumeeComponent<
    IEditAppInfoProps,
    EditAppInfoState
> {
    constructor(props: IEditAppInfoProps) {
        super('EditAppInfo', props);
    }

    render() {
        const {
            iconUrl,
            name,
            url = '',
            orientation,
            pattern,
            onIconError,
            onIconUrlChange,
            onNameChange,
            verifyAppName,
            onUrlChange,
        } = this.props;

        return (
            <div id={this.id} className="app-info-data">
                <img
                    className="icon"
                    src={iconUrl}
                    alt="App icon"
                    onError={onIconError}
                />
                <Spacer
                    size={orientation === 'horizontal' ? 'tiny' : 'medium'}
                    direction={orientation}
                />
                <div className="inputs">
                    <InputText
                        fatherId={this.id}
                        usage="icon"
                        value={iconUrl}
                        onValueChange={onIconUrlChange}
                        errorMessage={AumeeTranslator.translate(
                            'app.icon_invalid',
                        )}
                        placeholder={AumeeTranslator.translate('app.icon')}
                        pattern={pattern.iconUrlInvalid}
                        minLength={10}
                        errorMinLength={AumeeTranslator.translate(
                            'common.too_short',
                        )}
                        maxLength={200}
                        errorMaxLength={AumeeTranslator.translate(
                            'common.too_long',
                        )}
                    />
                    <Spacer size="small" />
                    <InputText
                        fatherId={this.id}
                        usage="app-name"
                        size="medium"
                        value={name}
                        placeholder={AumeeTranslator.translate('app.app_name')}
                        onValueChange={onNameChange}
                        minLength={4}
                        errorMinLength={AumeeTranslator.translate(
                            'common.too_short',
                        )}
                        maxLength={50}
                        errorMaxLength={AumeeTranslator.translate(
                            'common.too_long',
                        )}
                        pattern={pattern.appName}
                        errorMessage={AumeeTranslator.translate(
                            'app.app_name_exists',
                        )}
                        onFocusOut={verifyAppName}
                    />
                    {onUrlChange && <Spacer size="small" />}
                    {onUrlChange && (
                        <InputText
                            fatherId={this.id}
                            usage="url"
                            size="medium"
                            value={url}
                            placeholder={AumeeTranslator.translate(
                                'app.app_url',
                            )}
                            onValueChange={onUrlChange}
                            validationMinLength={1}
                            minLength={10}
                            errorMinLength={AumeeTranslator.translate(
                                'common.too_short',
                            )}
                            maxLength={100}
                            errorMaxLength={AumeeTranslator.translate(
                                'common.too_long',
                            )}
                        />
                    )}
                </div>
            </div>
        );
    }
}
