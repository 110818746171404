import { Direction } from '@dyrloz/aumee-design';

export default [
    {
        id: 'new-app_edit-app-info_input-text_icon',
        position: 'vertical' as Direction,
        text: 'new_app.assistant.icon',
        maxWidth: 250,
    },
    {
        id: 'new-app_edit-app-info_input-text_app-name',
        position: 'vertical' as Direction,
        text: 'new_app.assistant.name',
        maxWidth: 250,
    },
    {
        id: 'new-app_edit-app-info_input-text_url',
        position: 'vertical' as Direction,
        text: 'new_app.assistant.url',
        maxWidth: 250,
    },
    {
        id: 'new-app_colors',
        position: 'vertical' as Direction,
        text: 'new_app.assistant.colors',
        maxWidth: 300,
    },
    {
        id: 'new-app_button_create',
        position: 'vertical' as Direction,
        text: 'new_app.assistant.create',
        maxWidth: 200,
    },
];
