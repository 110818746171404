import { Direction } from '@dyrloz/aumee-design';

export default [
    {
        id: 'log-this-out_container_inputs',
        position: 'vertical' as Direction,
        text: 'logs.assistant.filters',
        maxWidth: 350,
    },
    {
        id: 'log-this-out_date-time-picker_start-date',
        position: 'vertical' as Direction,
        text: 'logs.assistant.start_date',
        maxWidth: 250,
    },
    {
        id: 'log-this-out_date-time-picker_end-date',
        position: 'vertical' as Direction,
        text: 'logs.assistant.end_date',
        maxWidth: 250,
    },
    {
        id: 'log-this-out_select_type',
        position: 'vertical' as Direction,
        text: 'logs.assistant.type',
        maxWidth: 250,
    },
    {
        id: 'log-this-out_select_session',
        position: 'vertical' as Direction,
        text: 'logs.assistant.session_id',
        maxWidth: 250,
    },
    {
        id: 'log-this-out_select_trace',
        position: 'vertical' as Direction,
        text: 'logs.assistant.trace_id',
        maxWidth: 250,
    },
    {
        id: 'log-this-out_select_class',
        position: 'vertical' as Direction,
        text: 'logs.assistant.class_name',
        maxWidth: 200,
    },
    {
        id: 'log-this-out_button_refresh',
        position: 'horizontal' as Direction,
        text: 'logs.assistant.refresh',
        maxWidth: 200,
    },
    {
        id: 'log-this-out_table_logs_row-headers_date',
        position: 'vertical' as Direction,
        text: 'logs.assistant.sort_date',
        maxWidth: 250,
    },
    {
        id: 'log-this-out_table_logs_row-headers_type',
        position: 'horizontal' as Direction,
        text: 'logs.assistant.sort_type',
        maxWidth: 250,
    },
    {
        id: 'log-this-out_table_logs_row-1',
        position: 'vertical' as Direction,
        text: 'logs.assistant.first_log',
        maxWidth: 350,
    },
    {
        id: 'log-this-out_table_logs_input-number_range',
        position: 'horizontal' as Direction,
        text: 'logs.assistant.range',
        maxWidth: 200,
    },
    {
        id: 'log-this-out_table_logs_button_display-total',
        position: 'horizontal' as Direction,
        text: 'logs.assistant.page',
        maxWidth: 200,
    },
];
