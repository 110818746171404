import { Direction } from '@dyrloz/aumee-design';

export default [
    {
        id: 'translator_select-locale_locale-display',
        position: 'vertical' as Direction,
        text: 'translator.assistant.locale',
        maxWidth: 200,
    },
    {
        id: 'translator_input-text_search',
        position: 'horizontal' as Direction,
        text: 'translator.assistant.search',
        maxWidth: 250,
    },
    {
        id: 'translator_row-1',
        position: 'vertical' as Direction,
        text: 'translator.assistant.translation',
        maxWidth: 300,
    },
    {
        id: 'translator_versions',
        position: 'vertical' as Direction,
        text: 'translator.assistant.versions',
        maxWidth: 300,
    },
];
